<template>
  <PrimeGlobalToast/>
  <PrimeConfirmDialog :draggable="false"/>
  <PrimeDynamicDialog :draggable="false"/>
  <PrimeGlobalDownloadDialog/>
  <GlobalAppUpdateDialog/>
  <!-- <TermsOfUse/> -->

  <!-- Sidebars -->
  <CalendarSidebar ref="calendarSidebar"/>
  <MobileSidebar ref="mobileSidebar"/>
  <SearchSidebar ref="searchSidebar"/>
  <SupportSidebar ref="supportSidebar"/>

  <!-- Build Timestamp -->
  <span hidden>{{buildTimestamp}}</span>

  <!-- Application -->
  <div class="page-container">
    <nav class="page-container-nav">
      <PageNavigation/>
    </nav>

    <header class="page-container-header">
      <PageHeader
          @open-search="() => searchSidebar?.open()"
          @open-calendar="() => calendarSidebar?.open()"
          @open-menu-mobile="() => mobileSidebar?.open()"
      />
    </header>

    <main class="page-container-main">
      <div class="app-loading" v-if="baseError">
        <Message severity="error" :closable="false" class="w-[50%] flex flex-col">
          <div class="font-semibold text-center">
            {{ $t('errors.base_error.part_1') }}
          </div>
          <div class="text-current" v-html="$t('errors.base_error.part_2')"></div>
        </Message>
      </div>

      <div class="app-loading" v-else-if="loading">
        <LoadingFeedback :label="$t('loadingApp')"/>
      </div>

      <PageScreen v-else v-slot="{ Component }">
        <template v-if="error">
          <ErrorFeedback :message="error"/>
        </template>

        <template v-else>
          <template v-if="(Component?.type && ((Component?.type as any).emits || []).includes('fatal'))">
            <component :is="Component" @fatal="(err?: any) => onPageError(err)"/>
          </template>
          <template v-else>
            <Transition name="fade">
              <component :is="Component"/>
            </Transition>
          </template>
        </template>
      </PageScreen>
    </main>

    <StatisticalDataPage v-if="showStatisticalData"/>
  </div>
</template>

<script lang="ts" setup>
import {useWindowSize, watchDebounced} from '@vueuse/core';
import PrimeConfirmDialog from 'primevue/confirmdialog';
import PrimeDynamicDialog from 'primevue/dynamicdialog';
import {onMounted, ref, watch} from 'vue';
import {RouterView as PageScreen, useRouter} from 'vue-router';
import Message from "primevue/message";

import {useBackgroundWebsocket} from '@/composables/http/use-background-websocket';
import {useCampusWebsocketTicket} from '@/composables/http/use-campus-ws-ticket';
import {DeviceState} from '@/models/DeviceState';
import {useInfoStore} from '@/stores/info';
import {usePersonStore} from '@/stores/person';
import {useMiscStore} from '@/stores/misc';
import {useSitemapStore} from '@/stores/sitemap';
import ErrorFeedback from '@/components/feedback/ErrorFeedback.vue';
import LoadingFeedback from '@/components/feedback/LoadingFeedback.vue';
import PageHeader from '@/components/page/PageHeader.vue';
import PageNavigation from '@/components/page/PageNavigation.vue';
import PrimeGlobalToast from '@/components/primevue/GlobalToast.vue';
import PrimeGlobalDownloadDialog from '@/components/primevue/GlobalDownloadDialog.vue';
import CalendarSidebar from '@/components/sidebars/CalendarSidebar.vue';
import MobileSidebar from '@/components/sidebars/MobileSidebar.vue';
import SearchSidebar from '@/components/sidebars/SearchSidebar.vue';
import SupportSidebar from '@/components/sidebars/SupportSidebar.vue';
import {useI18n} from "vue-i18n";
import TermsOfUse from "@/components/shared/TermsOfUse.vue";
import GlobalAppUpdateDialog from "@/components/primevue/GlobalAppUpdateDialog.vue";
import {storeToRefs} from "pinia";
import StatisticalDataPage from "@/pages/statistical-data/StatisticalDataPage.vue";

// Stores
const {fetchRoutes} = useSitemapStore();
const {baseError, showStatisticalData} = storeToRefs(useSitemapStore());
const {t} = useI18n();
const {fetchTicket} = useCampusWebsocketTicket();
const {fetchInfoLists, fetchBuddies} = useInfoStore();
const {fetchCompactProfile} = usePersonStore();
const miscStore = useMiscStore();
const router = useRouter();

// States
const error = ref<string>();
const loading = ref<boolean>(true);
const calendarSidebar = ref<InstanceType<typeof CalendarSidebar> | null>(null);
const searchSidebar = ref<InstanceType<typeof SearchSidebar> | null>(null);
const mobileSidebar = ref<InstanceType<typeof MobileSidebar> | null>(null);
const {width} = useWindowSize();
const buildTimestamp = ref<string>(BUILD_TIMESTAMP || '');


/**
 * Component mounted
 */
onMounted(async () => {
  loading.value = true;

  // Initialize Application
  fetchRoutes().then(() => {
    // Loading Done
    loading.value = false;
  }).catch(err => console.error(err));
  fetchInfoLists().catch(err => console.error(err));
  fetchCompactProfile();
  fetchBuddies().catch(err => console.error(err));

  // Open Background WebSocket
  try {
    const result = await fetchTicket();
    const websocket = useBackgroundWebsocket(`${import.meta.env.VITE_WS_CAMPUS}/${result.ticket}`);
    websocket.open();
  } catch (err) {
    console.error(err);
    return;
  }
});

/**
 * Watch Window Size, Handle mobile Calendar / Menu
 */
watchDebounced(width, () => {
  miscStore.deviceState = width.value < 1024 ? DeviceState.MOBILE : DeviceState.DESKTOP;

  if (miscStore.deviceState === DeviceState.DESKTOP) {
    miscStore.mobileCalendarOpen = false;
    miscStore.mobileMenuOpen = false;
  }
}, {debounce: 100});

/**
 * Reset error message on route changes
 */
watch(router.currentRoute, () => {
  error.value = void 0;
});

/**
 * Handle Page Error
 * @param err
 */
function onPageError(err: Error | string | null) {
  if (!import.meta.env.PROD) {
    console.error(err);
  }
  error.value = err?.toString() || t('ToastMessages.ErrorCodes.Default');
}


</script>

<style>
#app {
  @apply w-full flex flex-col h-[100svh];
}
</style>

<style scoped>
.app-loading {
  @apply w-full h-full flex items-center justify-center;
  @apply bg-white/90;
  z-index: 9999;
}

.page-container {
  @apply w-full h-full grid overflow-hidden;
  grid-template-columns: 100%;
  grid-template-rows: 9rem 1fr;

  @screen lg {
    grid-template-columns: 80px 1fr;
    grid-template-rows: 8rem 1fr;
  }
}

.page-container-nav {
  @apply row-span-2 hidden lg:flex flex-col ;
  @apply bg-primary-500;
}

.page-container-header {
  @apply overflow-hidden;
  @apply border-0 border-b border-solid;
  @apply bg-zinc-50 border-zinc-100;
}

.page-container-main {
  @apply overflow-hidden;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.33s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>

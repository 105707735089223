<template>
  <div class="w-full h-full overflow-y-auto">
    <div class="w-full h-full p-4 lg:p-8">
      <form @submit="onSubmit">
        <div>
          <h2 class="mb-4 text-lg">
            {{ $t('EducationStep.PersonalInformation.Headline') }}
          </h2>
          <div class="flex flex-col mb-4">
            <p class="mb-1 text-base text-zinc-800">{{ $t('EducationStep.PersonalInformation.CitizenshipHeader') }}</p>
            <label for="name" class="mb-1 text-sm text-zinc-800">{{
              $t('EducationStep.PersonalInformation.CitizenShipOne') }}</label>
            <NfDropdown inputName="education.citizenShipOne"
              :options="questionnaireDropDownData.staatsangehoerigkeitViewModels ?? []" optionLabel="value"
              optionValue="id" :placeholder="$t('EducationStep.PersonalInformation.SelectCountry')" :pt="dropDownPt" />
          </div>
          <div class="flex flex-col mb-4">
            <label for="name" class="mb-1 text-sm text-zinc-800">{{
              $t('EducationStep.PersonalInformation.CitizenShipTwo') }}</label>
            <NfDropdown inputName="education.citizenShipTwo"
              :options="questionnaireDropDownData.staatsangehoerigkeitZweiViewModels ?? []" optionLabel="value"
              optionValue="id" :placeholder="$t('EducationStep.PersonalInformation.SelectCountry')" :pt="dropDownPt" />
          </div>
          <div class="flex flex-col mb-4">
            <label for="name" class="mb-1 text-sm text-zinc-800">{{
              $t('EducationStep.PersonalInformation.CountryOfBirth') }}</label>
            <NfDropdown inputName="education.countryOfBirth"
              :options="questionnaireDropDownData.geburtslandViewModels.filter((country) => country.id !== '999') ?? []"
              optionLabel="value" optionValue="id" :placeholder="$t('EducationStep.PersonalInformation.SelectCountry')"
              :pt="dropDownPt" />
          </div>
          <div class="flex flex-col mb-4">
            <label for="name" class="mb-1 text-sm text-zinc-800">{{ $t('EducationStep.PersonalInformation.PlaceOfBirth')
              }}</label>
            <NfInputText inputName="education.placeOfBirth"
              :label="$t('EducationStep.PersonalInformation.TypePlace')" />
            <!-- <NfDropdown inputName="education.placeOfBirth" filter :options="questionnaireDropDownData.cityViewModels ?? []" optionLabel="value" optionValue="id" placeholder="Ort auswählen" /> -->
          </div>
          <div class="flex flex-col mb-4">
            <p class="mb-3 text-base text-zinc-800">{{ $t('EducationStep.PersonalInformation.CurrentJob.Label') }}</p>
            <div class="w-fit">
              <NfRadioButtonGroup inputName="education.currentJob"
                :options="[{ label: $t('EducationStep.PersonalInformation.CurrentJob.Yes'), value: true }, { label: $t('EducationStep.PersonalInformation.CurrentJob.No'), value: false }]"
                :hide-error="false" />
            </div>
          </div>
          <Message v-if="values.education.currentJob === false" :closable="false" severity="info">
            <p class="text-sm">
              {{ $t("EducationStep.PersonalInformation.CurrentJob.study_into_the_job") }}
            </p>
          </Message>
        </div>
        <div class="mb-8">
          <h2 class="mb-4 text-lg">
            {{ $t('EducationStep.Headline') }}
          </h2>
          <div class="flex items-center gap-1 text-primary-600">
            <p class="m-0 mb-1">{{ $t('EducationStep.Intro') }}</p>
            <IconInfoOutline v-tooltip="$t('EducationStep.IntroHint')" class="text-sm" />
          </div>
        </div>
        <div class="flex flex-col mb-4">
          <label for="name" class="flex items-center gap-1 mb-1 text-sm text-zinc-800">{{
            $t('EducationStep.HzbType.label') }}
            <IconInfoOutline class="text-sm text-primary-600" v-tooltip="{
              content: $t('EducationStep.HzbTypeHint'),
              html: true,
            }" />
          </label>
          <NfDropdown inputName="education.hzbTypeId" :options="questionnaireDropDownData.hochschulTypViewModels ?? []"
            optionLabel="value" optionValue="id" :placeholder="$t('EducationStep.HzbType.placeholder')"
            :pt="dropDownPt" />
        </div>
        <div class="flex flex-col mb-4">
          <label for="name" class="flex items-center gap-1 mb-1 text-sm text-zinc-800">{{
            $t('EducationStep.HzbYear.label') }}
            <IconInfoOutline class="text-sm text-primary-600" v-tooltip="$t('EducationStep.HzbYearHint')" />
          </label>
          <NfDropdown inputName="education.hzbYear"
            :options="[...props.questionnaireDropDownData.yearViewModels].reverse() ?? []" optionLabel="value"
            optionValue="id" :placeholder="$t('EducationStep.HzbYear.placeholder')" :pt="dropDownPt" />
        </div>
        <div v-if="values.education.hzbTypeId === '39' || values.education.hzbTypeId === '59'"
          class="flex flex-col mb-4">
          <label for="name" class="mb-1 text-sm text-zinc-800">{{ $t("EducationStep.HzbCountry.label") }}</label>
          <NfDropdown inputName="education.hzbCountryId"
            :options="props.questionnaireDropDownData.geburtslandViewModels?.slice(2) ?? []" optionLabel="value"
            optionValue="id" :placeholder="$t('EducationStep.HzbCountry.placeholder')" :pt="dropDownPt" />
        </div>
        <div v-if="values.education.hzbTypeId !== '39' && values.education.hzbTypeId !== '59'"
          class="flex flex-col mb-4">
          <label for="name" class="mb-1 text-sm text-zinc-800">{{ $t("EducationStep.HzbCity.label") }}</label>
          <NfDropdown inputName="education.hzbCityId"
            :options="questionnaireDropDownData.cityViewModels?.slice(1) ?? []" :optionLabel="'value'" optionValue="id"
            :placeholder="$t('EducationStep.HzbCity.placeholder')" :pt="dropDownPt" filter />
        </div>
        <div class="flex flex-col mb-4">
          <p class="mb-1 text-base text-zinc-800">{{ $t("EducationStep.EducationStatusInfo") }}</p>
          <div class="w-fit">
            <NfRadioButtonGroup inputName="education.educationStatusInfo"
              :options="[{ label: $t('EducationStep.yes'), value: true }, { label: $t('EducationStep.no'), value: false }]"
              :hide-error="false" class="[&>div]:gap-8 [&>div>div]:gap-2 text-zinc-800" />
          </div>
        </div>
        <div class="flex flex-col mb-4">
          <label for="name" class="mb-1 text-sm text-zinc-800">{{ $t('EducationStep.HzbTraining.label') }}</label>
          <NfDropdown inputName="education.hzbTraining"
            :options="questionnaireDropDownData.ausbildungsberufViewModels ?? []" optionLabel="value" optionValue="id"
            :placeholder="$t('EducationStep.HzbTraining.placeholder')" :pt="dropDownPt" />
        </div>
        <div v-if="values.education.hzbTraining && values.education.hzbTraining !== '-'" class="flex flex-col mb-4">
          <p class="mb-1 text-base text-zinc-800">{{ $t('EducationStep.HzbTrainingFinished') }}</p>
          <div class="w-fit">
            <NfRadioButtonGroup inputName="education.hzbTrainingFinished"
              :options="[{ label: $t('EducationStep.yes'), value: true }, { label: $t('EducationStep.no'), value: false }]"
              :hide-error="false" class="[&>div]:gap-8 [&>div>div]:gap-2 text-zinc-800" />
          </div>
        </div>
        <div class="flex flex-col mb-4">
          <label for="name" class="mb-1 text-sm text-zinc-800">{{ $t('EducationStep.FurtherTraining.label') }}</label>
          <NfDropdown inputName="education.furtherTraining"
            :options="questionnaireDropDownData.weiterbildungViewModels ?? []" optionLabel="value" optionValue="id"
            :placeholder="$t('EducationStep.FurtherTraining.placeholder')" :pt="dropDownPt" />
        </div>
        <div class="flex flex-col mb-4">
          <p class="mb-1 text-base text-zinc-800">{{ $t('EducationStep.VocationalSchoolParticipation') }}</p>
          <div class="w-fit">
            <NfRadioButtonGroup inputName="education.vocationalSchoolParticipation"
              :options="[{ label: $t('EducationStep.yes'), value: true }, { label: $t('EducationStep.no'), value: false }]"
              :hide-error="false" class="[&>div]:gap-8 [&>div>div]:gap-2 text-zinc-800" />
          </div>
        </div>
        <div v-if="values.education.vocationalSchoolParticipation" class="flex flex-col mb-4">
          <label for="name" class="mb-1 text-sm text-zinc-800">{{
            $t('EducationStep.VocationalSchoolParticipationId.label') }}</label>
          <NfDropdown inputName="education.vocationalSchoolParticipationId"
            :options="questionnaireDropDownData.berufskollegViewModels ?? []" optionLabel="value" optionValue="id"
            :placeholder="$t('EducationStep.VocationalSchoolParticipationId.placeholder')" :pt="dropDownPt" />
        </div>
        <div class="flex flex-col mb-4">
          <p class="mb-1 text-base text-zinc-800">{{ $t('EducationStep.CollegeUniversityExperience') }}</p>
          <div class="w-fit">
            <NfRadioButtonGroup inputName="education.collegeUniversityExperience"
              :options="[{ label: $t('EducationStep.yes'), value: true }, { label: $t('EducationStep.no'), value: false }]"
              :hide-error="false" class="[&>div]:gap-8 [&>div>div]:gap-2 text-zinc-800" />
          </div>
        </div>
        <div class="flex justify-end">
          <Button :label="$t('EducationStep.Next')" type="submit" class="flex-1 mr-2 lg:flex-none" />
        </div>
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { QuestionnaireDropDownData } from "@/types/contracts/generated/models/questionnaireDropDownData";
import type { Education } from "./StatisticalDataTypes";

import { watch } from "vue";
import { useI18n } from "vue-i18n";
import { useForm } from "vee-validate";
import { boolean, object, string } from "yup";
import Button from 'primevue/button';
import NfRadioButtonGroup from "@/components/form/NfRadioButtonGroup.vue";
import NfDropdown from "@/components/form/NfDropdown.vue";
import IconInfoOutline from '~icons/mdi/info-outline';
import NfInputText from "@/components/form/NfInputText.vue";
import Message from "primevue/message";

const props = defineProps<{
  education: Education,
  questionnaireDropDownData: QuestionnaireDropDownData,
}>();

const emit = defineEmits<{
  formComplete: [data: any];
  test: [value: boolean];
}>();

const { t } = useI18n();

const { values, setFieldValue, setFieldError, handleSubmit } = useForm({
  initialValues: {
    education: props.education,
  },
  validationSchema: object().shape({
    education: object().shape({
      citizenShipOne: string().label('PreviousEducationStep.Generic').required(),
      citizenShipTwo: string().label('PreviousEducationStep.Generic').when('citizenShipOne', (citizenShipOne, schema) =>
        schema.test('not-equal-to-citizenShipOne', t("EducationStep.PersonalInformation.CitizenShipsEqualError"), function (citizenShipTwo) {
          if (citizenShipOne === undefined || citizenShipTwo === undefined) {
            return true;
          }
          return citizenShipTwo !== this.parent.citizenShipOne;
        })
      ),
      countryOfBirth: string().label('PreviousEducationStep.Generic').required(),
      placeOfBirth: string().label('PreviousEducationStep.Generic').required(),
      currentJob: boolean().label('PreviousEducationStep.Generic').required(),
      hzbYear: string().label('PreviousEducationStep.Generic').required(),
      hzbTypeId: string().label('PreviousEducationStep.Generic').required(),
      hzbCountryId: string().label('PreviousEducationStep.Generic').when('hzbTypeId', {
        is: (value: string) => value == '39' || value == '59',
        then: schema => schema.required()
      }),
      hzbCityId: string().label('PreviousEducationStep.Generic').when('hzbTypeId', {
        is: (value: string) => value !== '39' && value !== '59',
        then: schema => schema.required()
      }),
      educationStatusInfo: boolean().label('PreviousEducationStep.Generic').required(),
      hzbTraining: string().label('PreviousEducationStep.Generic').required(),
      hzbTrainingFinished: boolean().label('PreviousEducationStep.Generic').when('hzbTraining', {
        is: (value: string) => value !== '-',
        then: schema => schema.required()
      }),
      furtherTraining: string().label('PreviousEducationStep.Generic').required(),
      vocationalSchoolParticipation: boolean().label('PreviousEducationStep.Generic').required(),
      vocationalSchoolParticipationId: string().label('PreviousEducationStep.Generic').when('vocationalSchoolParticipation', {
        is: true,
        then: schema => schema.required()
      }),
      collegeUniversityExperience: boolean().label('PreviousEducationStep.Generic').required()
    }).required(),
  })
});

watch(
  () => values.education.vocationalSchoolParticipationId,
  (newValue) => {
    setFieldValue("education.vocationalSchoolParticipation", newValue ? true : false);
  }
);

watch(
  () => values.education.hzbTypeId,
  (newValue) => {
    if (newValue == '39' || newValue == '59') {
      setFieldValue("education.hzbCityId", undefined);
      if (values.education.hzbCountryId === '000') setFieldValue("education.hzbCountryId", undefined)
    }
  }
);

const onSubmit = handleSubmit(values => {
  // clean up formdata
  if (values.education.hzbTypeId === '39' || values.education.hzbTypeId === '59') setFieldValue("education.hzbCityId", undefined)
  if (values.education.hzbTraining === "52026") setFieldValue("education.hzbTrainingFinished", false)
  emit('formComplete', values.education);
}, (errors) => {
  const firstError = Object.keys(errors.errors)[0];
  const el = document.getElementById(firstError);
  if (el) {
    el.style.scrollMarginTop = "111px"; // form element below fixed step display
    el.scrollIntoView({
      behavior: 'smooth',
    });
    el.focus();
  }
});

const dropDownPt = {
  panel: {
    class: 'dropdown-pt'
  }
}
</script>

<style scoped>
label {
  @apply text-zinc-800;
}

/* :deep(.p-radiobutton+label) {
    @apply whitespace-nowrap;
  } */

.fade-out {
  &::before {
    @apply content-[''] absolute bottom-full left-0 w-full h-4 bg-gradient-to-b from-white/0 to-white;
  }
}

:deep(.dropdown-pt) {
  @apply max-w-[calc(100vw-4rem)] lg:max-w-none;
}
</style>

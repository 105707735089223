// import 'primevue/resources/themes/md-light-indigo/theme.css'
import './styles/theme.css';
import './polyfill.ts';

import { createPinia } from 'pinia';
import { createApp, markRaw } from 'vue';
import { setLocale } from 'yup';

import FloatingVue, { placements } from 'floating-vue';
import { vMaska as MaskaDirective } from 'maska';
import PrimeVue from 'primevue/config';

import BadgeDirective from 'primevue/badgedirective';
import ConfirmationService from 'primevue/confirmationservice';
import DialogService from 'primevue/dialogservice';
import ToastService from 'primevue/toastservice';

import App from '@/App.vue';
import useAuth from '@/composables/use-auth';
import FileSizeDirective from '@/directives/filesize';
import ResizeDirective from '@/directives/resize';
import appPlugin from '@/plugins/app';
import createI18n from '@/plugins/i18n';
import createRouter from '@/plugins/router';
import legacyPlugin from '@/plugins/legacy';
import { useResource } from '@/composables/http/use-resource';


/**
 * Main Handler
 */
async function main() {
  const { makePostRequest } = useResource();

  // Prepare Authentication / Authorization
  const auth = useAuth();
  window.sessionStorage.setItem('deepLink', window.location.pathname);

  // Prepare MockServiceWorker (dev only)
  if (import.meta.env.DEV && import.meta.env.VITE_MOCK_DATA === true) {
    const { worker } = await import('@/mocks/browser');
    await worker.start({
      onUnhandledRequest: 'bypass'
    });
  }

  // Check if user is logged in
  const loggedIn = await auth.isLoggedIn();
  if (!loggedIn) {
    auth.userManager.value.signinRedirect();
    return;
  }

  // Create CAMPUS Application
  const app = createApp(App);
  app.config.errorHandler = (err, instance, info) => {
    if (import.meta.env.PROD) {
      makePostRequest(`${import.meta.env.VITE_API_CAMPUS}/log`, {
        type: 'error',
        err,
        info
      });
    } else {
      console.error(err, info);
    }
  };
  app.config.warnHandler = (message, instance, trace) => {
    if (import.meta.env.PROD) {
      makePostRequest(`${import.meta.env.VITE_API_CAMPUS}/log`, {
        type: 'warn',
        message,
        trace
      });
    } else {
      console.warn(message, trace);
    }
  };

  // Prepare Locale
  const { i18nPlugin, localePrimeVue, localeYup } = await createI18n();
  setLocale(localeYup);

  // Prepare Router
  const routerPlugin = await createRouter();

  // Prepare Pinia Store
  const piniaPlugin = createPinia();
  piniaPlugin.use(({ store }) => {
    store.$i18n = markRaw(i18nPlugin.global as any);
    store.$router = markRaw(routerPlugin);
  });

  // Register custom plugins
  app.use(PrimeVue, {
    locale: localePrimeVue
  });
  app.use(ConfirmationService);
  app.use(DialogService);
  app.use(ToastService);
  app.use(FloatingVue, {
    themes: {
      examdetails: {
        $extend: 'tooltip'
      },
      navigation: {
        $extend: 'tooltip'
      },
      courseSignupTable: {
        $extend: 'tooltip',
        placement: 'bottom-start',
        triggers: ['hover', 'focus', 'touch', 'click']
      },
      subsequentDeregister: {
        $extend: 'tooltip'
      },
      writingTimeExtension: {
        $extend: 'tooltip'
      },
      courseSignupInfo: {
        $extend: 'tooltip',
      },
      calendarWeekInfo: {
        $extend: 'menu',
        placement: 'bottom-start',
        showTriggers: ['hover', 'click'],
        hideTriggers: ['click']
      }
    }
  });
  app.use(i18nPlugin);
  app.use(routerPlugin);
  app.use(piniaPlugin);
  app.use(appPlugin);
  app.use(legacyPlugin);

  // Register custom directives
  app.directive('badge', BadgeDirective);
  app.directive('file-size', FileSizeDirective);
  app.directive('maska', MaskaDirective);
  app.directive('resize', ResizeDirective);

  // Mount
  app.mount('#app');
}
main();

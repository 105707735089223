import {useResource} from "@/composables/http/use-resource";
import type {QuestionnaireDropDownData} from "@/types/contracts/generated/models/questionnaireDropDownData";
import type {AxiosResponse} from "axios";
import type {EducationStepModel} from "@/types/contracts/generated/models/educationStepModel";

const baseUrl = `${import.meta.env.VITE_API_CAMPUS}/hstatg`;

export function useCampusHstatgApi() {

    const {makeGetRequest, makePostRequest} = useResource();

    const getDropdownData = async (): Promise<QuestionnaireDropDownData> => makeGetRequest(`${baseUrl}/getDropdownData`);

    const saveStatisticData = async (form: EducationStepModel): Promise<AxiosResponse> => makePostRequest(`${baseUrl}/saveStatisticData`, form);

    return {
        getDropdownData,
        saveStatisticData
    };

}
<template>
  <div class="w-full h-full overflow-y-auto">
    <div class="w-full h-full p-4 lg:p-8">
      <form @submit="onSubmit">
        <div>
          <div v-for="(field, idx) in fields" :key="field.key"
            :class="fields.length > 1 ? 'mb-8 pb-4 last:mb-0 last:pb-0 border-0 border-b border-solid border-zinc-200 last:border-b-0' : ''">
            <div class="flex flex-col mb-4">
              <label for="name" class="mb-1 text-sm text-zinc-800">
                {{ `${$t('PreviousEducationStep.HaveYouSuccessfullyCompletedYourStudies.title')} *` }}
              </label>
              <div class="w-fit">
                <NfRadioButtonGroup :inputName="`previousEducation[${idx}].completed`"
                  :options="[{ label: $t('PreviousEducationStep.HaveYouSuccessfullyCompletedYourStudies.Yes'), value: true }, { label: $t('PreviousEducationStep.HaveYouSuccessfullyCompletedYourStudies.No'), value: false }]"
                  :hide-error="false" class="[&>div]:gap-8 [&>div>div]:gap-2 text-zinc-800"
                  :id="`previousEducation[${idx}].completed`" @change="handleReasonForExmatriculationId(idx)" />
              </div>
            </div>

            <div class="flex flex-col mb-4">
              <label for="name" class="mb-1 text-sm text-zinc-800">
                {{ `${$t('PreviousEducationStep.StudyPlace')} *` }}
              </label>
              <div class="w-fit">
                <NfRadioButtonGroup :inputName="`previousEducation[${idx}].studyPlace`"
                  :options="[{ label: $t('PreviousEducationStep.InGermany'), value: 'germany' }, { label: $t('PreviousEducationStep.Abroad'), value: 'abroad' }]"
                  :hide-error="false" @change="resetFormOnStudyPlaceChange(idx)"
                  class="[&>div]:gap-8 [&>div>div]:gap-2 text-zinc-800" />
              </div>
            </div>

            <template v-if="field.value.completed !== undefined && field.value.studyPlace !== undefined">
              <div class="flex flex-col mb-4">
                <label for="name" class="mb-1 text-sm text-zinc-800">
                  {{ `${$t('PreviousEducationStep.UniversityType')} *` }}
                </label>
                <NfDropdown :inputName="`previousEducation[${idx}].universityTypeId`"
                  :options="universityTypeIdList(idx) ?? []" optionLabel="value" optionValue="id"
                  placeholder="Hochschultyp auswählen" :disabled="field.value.studyPlace == 'abroad'"
                  :id="`previousEducation[${idx}].universityTypeId`" @change="handleUniversityTypeId(idx)" />
              </div>

              <template v-if="field.value.studyPlace === 'abroad'">
                <div class="mb-4">
                  <label for="name" class="mb-1 text-sm text-zinc-800">
                    {{ `${$t('PreviousEducationStep.Country')} *` }}
                  </label>
                  <NfDropdown :inputName="`previousEducation[${idx}].countryId`" :options="countryList(idx) ?? []"
                    optionLabel="value" optionValue="id" placeholder="Staat auswählen" filter />
                </div>
              </template>

              <div v-if="field.value.studyPlace == 'germany'" class="mb-4">
                <label for="name" class="mb-1 text-sm text-zinc-800">
                  {{ `${$t('PreviousEducationStep.City')} *` }}
                </label>
                <NfDropdown :inputName="`previousEducation[${idx}].cityId`" :options="cityList(idx) ?? []"
                  optionLabel="value" optionValue="id" placeholder="Stadt auswählen"
                  :filter="field.value.studyPlace === 'germany'" :disabled="field.value.universityTypeId == '52934'" />
              </div>

              <!-- <template
                v-if="questionnaireDropDownData.cityViewModels?.find(entry => entry.id?.toString() === field.value.cityId?.toString())?.other === true">
                <div class="mb-4">
                  <label for="name" class="mb-1 text-sm text-zinc-800">
                    {{ `${$t('PreviousEducationStep.CityOther')} *` }}
                  </label>
                  <NfInputText :inputName="`previousEducation[${idx}].cityOther`" />
                </div>
              </template> -->

              <div class="mb-4" v-if="field.value.studyPlace === 'germany'">
                <label for="name" class="mb-1 text-sm text-zinc-800">
                  {{ `${$t('PreviousEducationStep.University')} *` }}
                </label>
                <!-- <NfInputText v-if="field.value.studyPlace === 'abroad'" :inputName="`previousEducation[${idx}].universityId`" /> -->
                <NfDropdown :inputName="`previousEducation[${idx}].universityId`" :options="universityIdList(idx) ?? []"
                  optionLabel="value" optionValue="id" placeholder="Hochschule auswählen"
                  :disabled="field.value.universityTypeId == '52934'" filter />
                <!-- <Dropdown :v-model="`previousEducation[${idx}].universityId`" :options="questionnaireDropDownData.hochschuleViewModels ?? []" optionLabel="value" optionValue="id" placeholder="Hochschule auswählen" class="w-full md:w-14rem" /> -->
              </div>

              <!-- <template v-if="questionnaireDropDownData.hochschuleViewModels?.find(entry => entry.id?.toString() === field.value.universityId?.toString())?.other === true">
          <div class="mb-4">
            <label for="name" class="mb-1 text-sm text-zinc-800">
              {{ `${$t('PreviousEducationStep.UniversityOther')} *` }}
            </label>
            <NfInputText :inputName="`previousEducation[${idx}].universityOther`" />
          </div>
        </template> -->

              <div class="mb-4">
                <label for="name" class="mb-1 text-sm text-zinc-800">
                  {{ `${$t('PreviousEducationStep.FieldOfStudy')} *` }}
                </label>
                <NfDropdown :inputName="`previousEducation[${idx}].fieldOfStudyId`"
                  :options="questionnaireDropDownData.bundStudienfachViewModels ?? []" optionLabel="value"
                  optionValue="id" placeholder="Studienfach auswählen" filter />
              </div>

              <template
                v-if="questionnaireDropDownData.bundStudienfachViewModels?.find(entry => entry.id?.toString() === field.value.fieldOfStudyId?.toString())?.other === true">
                <div class="mb-4">
                  <label for="name" class="mb-1 text-sm text-zinc-800">
                    {{ `${$t('PreviousEducationStep.FieldOfStudyOther')} *` }}
                  </label>
                  <NfInputText :inputName="`previousEducation[${idx}].FieldOfStudyOther`" />
                </div>
              </template>

              <div class="mb-4">
                <label for="name" class="mb-1 text-sm text-zinc-800">
                  {{ `${$t('PreviousEducationStep.StudyModel')} *` }}
                </label>
                <NfDropdown :inputName="`previousEducation[${idx}].studyModelId`"
                  :options="questionnaireDropDownData.zeitmodellViewModels ?? []"
                  :disabled="field.value.studyPlace == 'abroad'" optionLabel="value" optionValue="id"
                  placeholder="Art des Studiums auswählen" />
              </div>

              <div class="mb-4">
                <label for="name" class="mb-1 text-sm text-zinc-800">
                  {{ `${$t('PreviousEducationStep.From')} *` }}
                </label>
                <NfDropdown :inputName="`previousEducation[${idx}].fromSemesterId`"
                  :options="[...questionnaireDropDownData.semesterKuerzelViewModels].reverse() ?? []"
                  optionLabel="value" optionValue="id" placeholder="Beginn auswählen" />
              </div>

              <div class="mb-4">
                <label for="name" class="mb-1 text-sm text-zinc-800">
                  {{ `${$t('PreviousEducationStep.To')} *` }}
                </label>
                <NfDropdown :inputName="`previousEducation[${idx}].toSemesterId`"
                  :options="filteredSemesterOptions(field.value.fromSemesterId ?? '') ?? []" optionLabel="value"
                  optionValue="id" placeholder="Ende auswählen" />
              </div>

              <div class="grid lg:gap-4"
                :class="values.previousEducation[idx].completed ? 'grid-cols-1 lg:grid-cols-3' : 'grid-cols-1'">

                <div class="mb-4" v-if="values.previousEducation[idx].completed">
                  <label for="name" class="mb-1 text-sm text-zinc-800">
                    {{ `${$t('PreviousEducationStep.Ects')} *` }}
                  </label>
                  <!-- <NfInputNumber v-model="values.previousEducation[idx].ects" :inputName="`previousEducation[${idx}].ects`" :step="1" :min="0" class="w-full" /> -->
                  <!-- @TODO: discuss value formatting -->
                  <NfDropdown :inputName="`previousEducation[${idx}].ects`"
                    :options="questionnaireDropDownData.ectsViewModels ?? []" optionLabel="value" optionValue="id"
                    placeholder="ECTS auswählen" />
                </div>

                <div class="mb-4">
                  <label for="name" class="mb-1 text-sm text-zinc-800">
                    {{ `${$t('PreviousEducationStep.NumberOfLeaveSemesters')} *` }}
                  </label>
                  <NfInputNumber :inputName="`previousEducation[${idx}].numberOfLeaveSemesters`"
                    v-model="values.previousEducation[idx].numberOfLeaveSemesters" :step="1" :min="0" :max="5"
                    :allow-empty="false" class="w-full" />
                </div>

                <div class="mb-4" v-if="values.previousEducation[idx].completed">
                  <label for="name" class="mb-1 text-sm text-zinc-800">
                    {{ `${$t('PreviousEducationStep.Grade')} *` }}
                  </label>
                  <!-- <NfInputNumber :inputName="`previousEducation[${idx}].gradeId`" v-model="values.previousEducation[idx].gradeId" :step="0.1" mode="decimal" :min-fraction-digits="0" :max-fraction-digits="1" :min="1" :max="5" :allow-empty="false" class="w-full" /> -->
                  <NfDropdown :inputName="`previousEducation[${idx}].gradeId`"
                    :options="questionnaireDropDownData.noteViewModels ?? []" optionLabel="value" optionValue="id"
                    placeholder="Abschlussnote auswählen" />
                </div>
              </div>

              <div v-if="values.previousEducation[idx].completed" class="mb-4">
                <label for="name" class="mb-1 text-sm text-zinc-800">
                  {{ `${$t('PreviousEducationStep.AcademicDegree')} *` }}
                </label>
                <NfDropdown :inputName="`previousEducation[${idx}].academicDegreeId`"
                  :options="academicDegreeIdList(idx) ?? []" :disabled="field.value.studyPlace == 'abroad'"
                  optionLabel="value" optionValue="id" placeholder="Akademischen Grad auswählen" filter />
              </div>

              <div v-if="field.value.completed" class="grid grid-cols-2 gap-4 mb-4">
                <div>
                  <label for="name" class="mb-1 text-sm text-zinc-800">
                    {{ `${$t('PreviousEducationStep.DateOfGraduationExamination')} *` }}
                  </label>
                  <NfDropdown :inputName="`previousEducation[${idx}].monthOfGraduationExamination`"
                    :options="questionnaireDropDownData.monthViewModels ?? []" optionLabel="value" optionValue="id"
                    placeholder="Monat auswählen" />
                </div>
                <div class="flex items-end">
                  <NfDropdown :inputName="`previousEducation[${idx}].yearOfGraduationExamination`"
                    :options="[...questionnaireDropDownData.yearViewModels].reverse() ?? []" optionLabel="value"
                    optionValue="id" class="w-full" placeholder="Jahr auswählen" />
                </div>
              </div>

              <div class="mb-4">
                <label for="name" class="mb-1 text-sm text-zinc-800">
                  {{ `${$t('PreviousEducationStep.ReasonForExmatriculation')} *` }}
                </label>
                <NfDropdown :inputName="`previousEducation[${idx}].reasonForExmatriculationId`"
                  :options="reasonForExmatriculationIdList(idx) ?? []" optionLabel="value" optionValue="id"
                  :disabled="field.value.completed" />
                <Message v-if="values.previousEducation[idx].reasonForExmatriculationId == '10'" severity="warn">
                  <p class="!text-zinc-700">
                  </p>
                  <p>
                    {{ $t('PreviousEducationStep.ReasonForExmatriculationMessage.first_text') }}
                    <span class="text-primary-600 cursor-help"
                      v-tooltip="$t('PreviousEducationStep.ReasonForExmatriculationMessage.tooltip_text')">
                      {{
                        $t('PreviousEducationStep.ReasonForExmatriculationMessage.highlighted_text')
                      }}
                      <IconInfoOutline class="text-sm translate-y-1" />
                    </span>
                    {{ $t('PreviousEducationStep.ReasonForExmatriculationMessage.second_text') }}
                  </p>
                  <ul>
                    <li>{{ $t('PreviousEducationStep.ReasonForExmatriculationMessage.list.first') }}</li>
                    <li>{{ $t('PreviousEducationStep.ReasonForExmatriculationMessage.list.second') }}</li>
                    <li>{{ $t('PreviousEducationStep.ReasonForExmatriculationMessage.list.third') }}</li>
                  </ul>
                </Message>
              </div>

              <div v-if="values.previousEducation[idx].reasonForExmatriculationId == '00'" class="mb-4">
                <label for="name" class="mb-1 text-sm text-zinc-800">
                  {{ `${$t('PreviousEducationStep.ReasonForTermination')} *` }}
                </label>
                <NfDropdown :inputName="`previousEducation[${idx}].reasonForTermination`"
                  :options="questionnaireDropDownData.abbruchViewModels" optionLabel="value" optionValue="id"
                  placeholder="Grund auswählen" />
              </div>

              <!-- <div class="mb-4">
          <label for="name" class="mb-1 text-sm text-zinc-800">
            {{ `${$t('PreviousEducationStep.ExmatriculationDate')} *` }}
          </label>
          <NfInputCalendar :inputName="`previousEducation[${idx}].exmatriculationDate`" :showIcon="true" :square="true" />
        </div> -->

              <div class="flex gap-2 mb-4">
                <SecondaryButton v-if="idx >= 1" size="small" type="button" severity="secondary" @click="remove(idx)"
                  :label="$t('PreviousEducationStep.remove')" />
                <SecondaryButton size="small" severity="secondary" type="button" @click="push({})"
                  label="+ Weitere Studienzeit" />
              </div>

            </template>


          </div>
        </div>

        <div class="flex flex-col justify-end gap-2 pt-4 bg-white lg:flex-row">
          <div class="flex justify-end gap-2">
            <Button :label="$t('EducationStep.Previous')" severity="secondary" class="flex-1 lg:flex-none"
              @click="prevCallback" />
            <Button
              v-if="values.previousEducation[0].completed !== undefined && values.previousEducation[0].studyPlace !== undefined"
              :label="$t('EducationStep.Next')" class="flex-1 lg:flex-none" type="submit" />
          </div>
        </div>

      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { QuestionnaireDropDownData } from "@/types/contracts/generated/models/questionnaireDropDownData";
import type { PreviousEducation } from "./StatisticalDataTypes";

import { useFieldArray, useForm } from "vee-validate";
import { array, boolean, number, object, string } from "yup";

import NfInputText from "@/components/form/NfInputText.vue";
import NfDropdown from "@/components/form/NfDropdown.vue";
import NfRadioButtonGroup from "@/components/form/NfRadioButtonGroup.vue";
import NfInputNumber from "@/components/form/NfInputNumber.vue";
import Button from "primevue/button";
import SecondaryButton from "@/components/buttons/SecondaryButton.vue";
import Message from "primevue/message";
import IconInfoOutline from '~icons/mdi/info-outline';

type ExtendedPreviousEducation = PreviousEducation & { index: number };

const props = defineProps<{
  previousEducations: ExtendedPreviousEducation[],
  questionnaireDropDownData: QuestionnaireDropDownData,
  prevCallback: (event: Event) => void;
}>()
const emit = defineEmits<{
  formComplete: [previousEducations: PreviousEducation[]]
}>();

const { handleSubmit, values, resetForm } = useForm({
  initialValues: {
    previousEducation: props.previousEducations,
  },
  validationSchema: object().shape({
    previousEducation: array().of(object().shape({
      completed: boolean().label('PreviousEducationStep.Generic').required(),
      studyPlace: string().label('PreviousEducationStep.Generic').trim().required(),
      universityTypeId: string().label('PreviousEducationStep.UniversityType').required(),
      countryId: string().label('countryId').when('PreviousEducationStep.Country', {
        is: 'abroad',
        then: schema => schema.required()
      }),
      cityId: string().label('PreviousEducationStep.City').when('studyPlace', {
        is: 'germany',
        then: schema => schema.required()
      }),
      // cityOther: string().label('PreviousEducationStep.CityOther').when('cityId', {
      //   is: (value: string) => props.questionnaireDropDownData.cityViewModels?.find(entry => entry.id?.toString() === value?.toString())?.other === true,
      //   then: schema => schema.trim().required()
      // }),
      universityId: string().label('PreviousEducationStep.University').when('studyPlace', {
        is: "germany",
        then: schema => schema.required()
      }),
      // universityOther: string().label('PreviousEducationStep.UniversityOther').when('universityId', {
      //   is: (value: string) => props.questionnaireDropDownData.hochschuleViewModels?.find(entry => entry.id?.toString() === value?.toString())?.other === true,
      //   then: schema => schema.trim().required()
      // }),
      fieldOfStudyId: string().label('PreviousEducationStep.FieldOfStudy').required(),
      fieldOfStudyOther: string().label('PreviousEducationStep.FieldOfStudyOther').when('fieldOfStudyId', {
        is: (value: string) => props.questionnaireDropDownData.bundStudienfachViewModels?.find(entry => entry.id?.toString() === value?.toString())?.other === true,
        then: schema => schema.trim().required()
      }),
      studyModelId: string().label('PreviousEducationStep.StudyModel').required(),
      fromSemesterId: string().label('PreviousEducationStep.From').required(),
      toSemesterId: string().label('PreviousEducationStep.To').required(),
      ects: string().label('PreviousEducationStep.Ects').when('completed', {
        is: true,
        then: schema => schema.required()
      }),
      numberOfLeaveSemesters: number().label('PreviousEducationStep.NumberOfLeaveSemesters').required(),
      gradeId: number().label('PreviousEducationStep.Grade').when('completed', {
        is: true,
        then: schema => schema.required()
      }),
      academicDegreeId: string().label('PreviousEducationStep.AcademicDegree').when('completed', {
        is: true,
        then: schema => schema.required()
      }),
      monthOfGraduationExamination: string().label('PreviousEducationStep.Generic').when('completed', {
        is: true,
        then: schema => schema.required()
      }),
      yearOfGraduationExamination: string().label('PreviousEducationStep.Generic').when('completed', {
        is: true,
        then: schema => schema.required()
      }),
      reasonForExmatriculationId: string().label('PreviousEducationStep.ReasonForExmatriculationId').required(),
      reasonForTermination: string().label('PreviousEducationStep.ReasonForTermination').when('reasonForExmatriculationId', {
        is: "999",
        then: schema => schema.required()
      }),
    })).min(1).required(),
  })
});

const { remove, push, fields, update } = useFieldArray<PreviousEducation>('previousEducation');

const resetFormOnStudyPlaceChange = (index: number) => {
  resetForm({
    values: {
      previousEducation: [
        ...values.previousEducation.slice(0, index),
        {
          ...values.previousEducation[index],
          completed: values.previousEducation[index]?.completed,
          studyPlace: values.previousEducation[index]?.studyPlace,
          universityTypeId: values.previousEducation[index]?.studyPlace == 'abroad' ? 'ausland' : undefined,
          cityId: undefined,
          countryId: undefined,
          universityId: undefined,
          fieldOfStudyId: undefined,
          studyModelId: values.previousEducation[index]?.studyPlace == 'abroad' ? 'praesenzstudium' : undefined,
          fromSemesterId: undefined,
          toSemesterId: undefined,
          ects: undefined,
          numberOfLeaveSemesters: 0,
          gradeId: undefined,
          academicDegreeId: values.previousEducation[index]?.studyPlace == 'abroad' && values.previousEducation[index].completed ? '96' : undefined,
          monthOfGraduationExamination: undefined,
          yearOfGraduationExamination: undefined,
          reasonForExmatriculationId: values.previousEducation[index].completed ? '09' : undefined
        },
        ...values.previousEducation.slice(index + 1),
      ],
    },
  });
};

const countryList = (index: number) => {
  if (values.previousEducation[index].studyPlace == "abroad") {
    return props.questionnaireDropDownData.geburtslandViewModels?.filter((country: any) => country.id !== '000' && country.id !== '999')
  } else return props.questionnaireDropDownData.geburtslandViewModels
}

const cityList = (index: number) => {
  if (props.questionnaireDropDownData.cityViewModels) {
    if (values.previousEducation[index].studyPlace === "abroad") return props.questionnaireDropDownData.cityViewModels[0]
    else {
      const slicedCityViewModels = props.questionnaireDropDownData.cityViewModels?.slice(1)
      // slicedCityViewModels.push({ id: "1", value: "Sonstige" })
      return slicedCityViewModels
    }
  }
}

const filteredSemesterOptions = (fromSemesterId: string) => {
  if (!fromSemesterId) {
    return []
  } else {
    const semesterid = props.questionnaireDropDownData?.semesterKuerzelViewModels?.find(s => s.id?.toString() === fromSemesterId?.toString())?.id || ''
    return props.questionnaireDropDownData.semesterKuerzelViewModels?.filter(s => (s.id ?? 0) >= semesterid)
  }
};

const reasonForExmatriculationIdList = (index: number) => {
  if (values.previousEducation[index].completed == false) {
    if (values.previousEducation[index].studyPlace == 'abroad') {
      return props.questionnaireDropDownData.exmatrikulationViewModels?.filter((reason: any) => reason.id !== '09' && reason.id !== '10')
    } else return props.questionnaireDropDownData.exmatrikulationViewModels?.filter((reason: any) => reason.id !== '09')
  }
  else return props.questionnaireDropDownData.exmatrikulationViewModels
}

const handleReasonForExmatriculationId = (index: number) => {
  update(index, {
    ...values.previousEducation[index],
    reasonForExmatriculationId: values.previousEducation[index].completed ? '09' : undefined,
    academicDegreeId: values.previousEducation[index].completed && values.previousEducation[index].studyPlace === 'abroad' ? '96' : undefined,
    gradeId: undefined
  })
}

const universityIdList = (index: number) => {
  if (values.previousEducation[index].universityTypeId !== '52934') {
    return props.questionnaireDropDownData.hochschuleViewModels?.filter((hochschule) => hochschule.id !== '695')
  } else return props.questionnaireDropDownData.hochschuleViewModels?.filter((hochschule) => hochschule.id == '695')
}

const handleUniversityTypeId = (index: number) => {
  if (values.previousEducation[index].universityTypeId == '52934') {
    update(index, {
      ...values.previousEducation[index],
      cityId: '1',
      universityId: '695'
    })
  } else values.previousEducation[index].universityId = undefined
}

const universityTypeIdList = (index: number) => {
  if (values.previousEducation[index].studyPlace == 'germany') return props.questionnaireDropDownData.ausbildungsTypViewModels?.filter((type: any) => type.id !== 'ausland')
  else return props.questionnaireDropDownData.ausbildungsTypViewModels?.filter((type: any) => type.id == 'ausland')
}

const academicDegreeIdList = (index: number) => {
  if (values.previousEducation[index].studyPlace == 'germany') return props.questionnaireDropDownData.titelViewModels?.filter((degree) => degree.id !== "96")
  else return props.questionnaireDropDownData.titelViewModels?.filter((degree) => degree.id == "96")
}

const onSubmit = handleSubmit(values => {
  emit('formComplete', values.previousEducation);
}, (errors) => {
  const firstError = Object.keys(errors.errors)[0];
  const el = document.getElementById(firstError);
  if (el) {
    el.style.scrollMarginTop = "111px"; // form element below fixed step display
    el.scrollIntoView({
      behavior: 'smooth',
    });
    el.focus();
  }
});
</script>

<style scoped>
label {
  @apply text-zinc-800;
}

:deep(.p-radiobutton+label) {
  @apply whitespace-nowrap;
}

:deep(.p-dropdown-panel) {
  max-width: calc(100vw - 2rem)
}
</style>

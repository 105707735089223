<template>
  <NfInputFloatLabel :inputName="inputName" :label="label">
    <InputText
      :id="inputName"
      v-model="value"
      :disabled="disabled"
      :readonly="readonly"
      :maxlength="maxlength"
      :class="{ 'p-invalid': hasError }"
      @input="(event) => emits('input', event)"
      :placeholder="label"
      class="w-full"
    />
  </NfInputFloatLabel>
</template>

<script lang="ts">
export interface NfInputTextProps {
  inputName?: string;
  modelValue?: string;
  label?: string;
  standalone?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  maxlength?: number;
}

export interface NfInputTextEmits {
  (e: 'update:modelValue', value: string): void;
  (e: 'input', value: Event): void;
}

// Default Export, used for IDE auto-importing only
export default {
  name: 'NfInputText'
}
</script>

<script lang="ts" setup>
import { rand } from '@vueuse/core';
import InputText from 'primevue/inputtext';
import NfInputFloatLabel from '@/components/form/NfInputFloatLabel.vue';
import { useFormField } from '@/composables/form/use-form-field';

// @todo Deprecated / Legacy Code
// Define Component
const props = withDefaults(defineProps<NfInputTextProps>(), {
  inputName: rand(0, 1000).toString(),
  disabled: false,
  readonly: false
});
const emits = defineEmits<NfInputTextEmits>();

// Form
const { value, hasError } = useFormField<string>(props, emits, !props.standalone);
</script>

<style scoped>
input::placeholder {
  @apply text-zinc-300;
}
</style>

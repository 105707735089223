import { createRouter, createWebHistory } from 'vue-router';

/**
 * Create vue-router
 */
async function createRouterPlugin() {
  const history = createWebHistory(import.meta.env.BASE_URL);
  const routes = [
    {
      path: '/messages',
      name: 'messages-feed',
      component: () => import('@/pages/messages/MessagesPage.vue'),
      meta: {
        title: 'meta.title.infos',
        view: 'messages/MessagesPage'
      },
      children: [
        {
          path: '',
          name: 'messages-list-core',
          redirect: { name: 'messages-list', params: { type: 'system' } }
        },
        {
          path: '/messages/:type',
          props: true,
          name: 'messages-list',
          component: () => import('@/pages/messages/subpages/ListPage.vue'),
          meta: {
            title: 'meta.title.infos'
          }
        },
        {
          path: '/messages/:type/:id',
          props: true,
          name: 'messages-detail',
          component: () => import('@/pages/messages/subpages/DetailPage.vue'),
          meta: {
            title: 'meta.title.infos'
          }
        },
        {
          path: '/messages/new',
          props: true,
          name: 'messages-new',
          component: () => import('@/pages/messages/subpages/FormPage.vue'),
          meta: {
            title: 'meta.title.messages_new'
          }
        },
        {
          path: '/messages/settings',
          props: true,
          name: 'messages-config',
          component: () => import('@/pages/messages/subpages/SettingsPage.vue'),
          meta: {
            title: 'meta.title.settings'
          }
        },
        {
          path: '/messages/contacts',
          props: true,
          name: 'messages-contacts',
          component: () => import('@/pages/messages/subpages/ContactsPage.vue'),
          meta: {
            title: 'meta.title.contacts'
          }
        }
      ]
    },
    {
      path: '/person/:personId',
      props: true,
      name: 'PersonDetail',
      component: () => import('@/pages/details/PersonDetailPage.vue'),
      meta: {
        title: 'meta.title.person_detail'
      }
    },
    {
      path: '/modul/:moduleId',
      props: true,
      name: 'ModuleDetail',
      component: () => import('@/pages/details/ModuleDetailPage.vue'),
      meta: {
        title: 'meta.title.module_description'
      }
    },
    {
      path: '/plp/:courseId',
      props: true,
      name: 'PLP',
      component: () => import('@/pages/plp/IndexPage.vue'),
      meta: {
        title: 'meta.title.plp',
        goBack: true
      }
    },
    {
      path: '/competence',
      component: () => import('@/pages/studybook/exam-submission/competence-check/CompetenceCheck.vue'),
      meta: {}
    },
    {
      path: '/competence-result',
      component: () => import('@/pages/studybook/exam-submission/competence-check/CompetenceCheckResult.vue'),
      meta: {}
    },
    {
      path: '/exam-submission/:peid',
      props: true,
      name: 'StudyBookExamSubmission',
      component: () => import('@/pages/studybook/exam-submission/StudyBookExamSubmission.vue'),
      meta: {
        title: 'meta.title.exam_submission'
      }
    },
    {
      path: '/exam-signup/:pid',
      props: true,
      component: () => import('@/pages/StaticPage.vue'),
      meta: {
        title: 'meta.title.exam_signup',
        view: 'studybook/exam-signup/StudyBookExamSignup'
      },
      children: [
        {
          path: '',
          name: 'StudyBookExamSignup',
          component: () => import('@/pages/studybook/exam-signup/StudyBookExamSignup.vue')
        }
      ]
    },
    {
      path: '/course-signup/:keyString',
      props: true,
      name: 'CourseSignup',
      component: () => import('@/pages/studies/CourseSignupPage.vue'),
      meta: {
        title: 'meta.title.course_signup'
      }
    },
    {
      path: '/optional-module-signup/:courseId/:fieldId',
      props: true,
      name: 'OptionalModuleSignup',
      component: () => import('@/pages/studies/OptionalModuleSignupPage.vue'),
      meta: {
        title: 'meta.title.optional_module_signup'
      }
    },
    {
      path: '/specializations/:courseId/:studyId',
      props: true,
      name: 'StudyBookSpecializations',
      component: () => import('@/pages/studybook/specializations/StudyBookSpecializationPage.vue'),
      meta: {
        title: 'meta.title.specializations'
      }
    },
    {
      path: '/objection/:peid',
      component: () => import('@/pages/objection/ObjectionContainerPage.vue'),
      name: 'ObjectionContainer',
      props: true,
      meta: {
        title: 'meta.title.objection'
      },
      children: [
        {
          path: '',
          component: () => import('@/pages/objection/ObjectionOverviewPage.vue'),
          name: 'ObjectionOverview',
          props: true,
          meta: {
            title: 'meta.title.objection'
          }
        },
        {
          path: 'details/:personId',
          component: () => import('@/pages/objection/ObjectionDetailsPage.vue'),
          name: 'ObjectionDetails',
          props: true,
          meta: {
            title: 'meta.title.objection'
          }
        },
        {
          path: 'edit/:personId',
          component: () => import('@/pages/objection/ObjectionEditPage.vue'),
          name: 'ObjectionEdit',
          props: true,
          meta: {
            title: 'meta.title.objection'
          }
        }
      ]
    },
    {
      path: '/support/:uuid',
      props: true,
      name: 'SupportActivationPage',
      component: () => import('@/pages/assl/SupportActivationPage.vue'),
      meta: {
        title: 'meta.title.support_activation'
      }
    },
    {
      path: '/validation',
      name: 'ValidationPlayground',
      component: () => import('@/pages/validation-playground/ValidationPlayground.vue'),
      meta: {
        title: 'meta.title.validation-playground'
      }
    },
    {
      path: '/hst',
      props: true,
      name: 'StatisticalDataPage',
      component: () => import('@/pages/statistical-data/StatisticalDataPage.vue'),
      meta: {
        title: 'meta.title.support_activation'
      }
    }
  ];

  // Create Vue Router with static routes first
  const router = createRouter({
    history,
    routes
  });

  // Return router plugin
  return router;
}

// Export Module
export default createRouterPlugin;

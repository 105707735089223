<template>
  <div class="flex flex-wrap w-full nf-radio-button-group" :id="props.id">
    <div
      class="flex flex-row w-full mb-2 radio-group"
      :class="{ 'flex-col items-start gap-3 justify-start pt-2': vertical }"
    >
      <div
        v-for="option in options"
        :key="option.label"
        class="flex items-center justify-center w-full nf-radio-button-group-radio-container"
        :class="{ '!justify-start gap-2': vertical }"
      >
        <RadioButton
          v-model="value"
          :inputId="`${inputName}_${typeof option.value === 'object' ? option.label : option.value}`"
          :value="option.value"
          :class="{ 'p-invalid': hasError && !hideError }"
          :disabled="disabled"
          :required="props.required"
          @change="$emit('change', option.value)"
        />
        <label
          :for="`${inputName}_${typeof option.value === 'object' ? option.label : option.value}`"
          v-html="option.label"
        ></label>
      </div>

    </div>
    <NfErrorMessage v-if="!hideError" />
  </div>
</template>

<script lang="ts">
export interface RadioButtonGroupOption {
  label: string;
  value: string | number | any;
}

export interface NfRadioButtonGroupProps {
  inputName?: string;
  modelValue?: string | number;
  options: RadioButtonGroupOption[];
  disabled?: boolean;
  hideError?: boolean;
  standalone?: boolean;
  vertical?: boolean;
  required?: boolean;
  id?: string;
}

export interface NfRadioButtonGroupEmits {
  (e: 'update:modelValue', value: string): void;
  (e: 'change', value: boolean): void;
}

// Default Export, used for IDE auto-importing only
export default {
  name: 'NfRadioButtonGroup'
}
</script>

<script lang="ts" setup>
import { rand } from '@vueuse/core';
import RadioButton from 'primevue/radiobutton';
import NfErrorMessage from '@/components/form/NfErrorMessage.vue';
import { useFormField } from '@/composables/form/use-form-field';


// @todo Deprecated / Legacy Code
// Define Component
const props = withDefaults(defineProps<NfRadioButtonGroupProps>(), {
  inputName: rand(0, 1000).toString(),
  hideError: true,
  vertical: false
});
const emits = defineEmits<NfRadioButtonGroupEmits>();

// Form
const { value, hasError } = useFormField(props, emits, !props.standalone);
</script>

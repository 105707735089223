<template>
  <form class="w-full h-full overflow-y-auto" @submit.prevent="handleSubmit">
    <div class="w-full h-full p-4 lg:p-8">
      <div class="flex flex-col gap-8">
        <div>
          <div class="w-full p-4 border border-solid rounded-lg border-zinc-200">
            <div class="mb-2 text-lg font-semibold">{{ $t("statistical_data_summary.educational_background") }}</div>
            <template v-for="(value, name) in props.education" :key="name">
              <div v-if="name !== 'previousEducations' && getEducationLabel(name)"
                class="grid grid-cols-1 mb-4 lg:mb-2 lg:gap-4 lg:grid-cols-2">
                <p>
                  {{ getEducationLabel(name)?.label }}:
                </p>
                <p class="text-primary-700 lg:text-zinc-800">
                  {{ getEducationLabel(name)?.value }}
                </p>
              </div>
            </template>
          </div>
        </div>

        <div v-if="education.previousEducations && education.previousEducations.length > 0">
          <div class="flex flex-col gap-8">
            <div v-for="(value, index) in education.previousEducations" :key="index"
              class="p-4 border border-solid rounded-lg border-zinc-200">
              <div class="mb-2 text-lg font-semibold">{{ $t("statistical_data_summary.student_days", { n: index + 1 })
              }}
              </div>
              <template v-for="(val, name, i) in value" :key="i">
                <div v-if="getPreviousEducationLabel(name, index)"
                  class="grid grid-cols-1 mb-4 lg:mb-2 lg:gap-4 lg:grid-cols-2">
                  <p>
                    {{ getPreviousEducationLabel(name, index).label }}
                  </p>
                  <p class="text-primary-700 lg:text-zinc-800">
                    {{ getPreviousEducationLabel(name, index).value }}
                  </p>
                </div>
              </template>
            </div>
          </div>
        </div>

      </div>

      <div class="flex justify-end gap-2 pt-4 bg-white lg:flex-row">
        <SecondaryButton :label="$t('EducationStep.Previous')" severity="secondary" @click="prevCallback"
          class="flex-1 lg:flex-none" />
        <PrimaryButton :label="$t('EducationStep.Confirm')" type="submit" class="flex-1 lg:flex-none" />
      </div>
    </div>
  </form>
</template>

<script setup lang="ts">
import type { Education } from './StatisticalDataTypes';
import type { QuestionnaireDropDownData } from '@/types/contracts/generated/models/questionnaireDropDownData';
import { computed } from 'vue';
import { getStatisticalDataEducationLabels, getStatisticalDataPreviousEducationLabels } from '../ts/statistical-data-summary-labels';
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import SecondaryButton from '@/components/buttons/SecondaryButton.vue';

const props = defineProps<{
  education: Education,
  questionnaireDropDownData: QuestionnaireDropDownData,
  prevCallback: (event: Event) => void,
}>();

const emit = defineEmits<{
  submit: any
}>();

const handleSubmit = () => {
  emit("submit")
};

const getEducationLabel = computed(() => {
  return (name: any) => {
    if (!props.education || !props.questionnaireDropDownData) return null;
    // for (const [key, value] of Object.entries(props.education)) {
    //   if (key !== "hzbCityId" && key !=="hzbTraining" && value === undefined) return null
    // }
    const map = getStatisticalDataEducationLabels(props.questionnaireDropDownData, props.education);
    return map.get(name);
  };
});

const getPreviousEducationLabel = computed(() => {
  return (name: any, index: number) => {
    const map = getStatisticalDataPreviousEducationLabels(props.questionnaireDropDownData, props.education, index)
    return map.get(name)
  }
});
</script>

<style scoped>
/* div, label, p {
  @apply text-zinc-800;
} */
p {
  @apply mb-0;
}
</style>

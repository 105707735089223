/**
 * [netzfactor] BCW Core Services API (netzfactor)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 * Contact: app@netzfactor.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ActionDtoType = 'link' | 'pruefungsergebnis' | 'StudyBookExamSubmission' | 'StudyBookExamSignup' | 'veranstaltungsanmeldung' | 'internal' | 'StudyBookExamLogoff' | 'StudyBookExamLogoffLate' | 'AbschlussArbeitBestaetigung' | 'Info' | 'Deckblatt' | 'wahlanmeldung' | 'themaRueckgabe' | 'themaAntragRueckzug';

export const ActionDtoType = {
    Link: 'link' as ActionDtoType,
    Pruefungsergebnis: 'pruefungsergebnis' as ActionDtoType,
    StudyBookExamSubmission: 'StudyBookExamSubmission' as ActionDtoType,
    StudyBookExamSignup: 'StudyBookExamSignup' as ActionDtoType,
    Veranstaltungsanmeldung: 'veranstaltungsanmeldung' as ActionDtoType,
    Internal: 'internal' as ActionDtoType,
    StudyBookExamLogoff: 'StudyBookExamLogoff' as ActionDtoType,
    StudyBookExamLogoffLate: 'StudyBookExamLogoffLate' as ActionDtoType,
    AbschlussArbeitBestaetigung: 'AbschlussArbeitBestaetigung' as ActionDtoType,
    Info: 'Info' as ActionDtoType,
    Deckblatt: 'Deckblatt' as ActionDtoType,
    Wahlanmeldung: 'wahlanmeldung' as ActionDtoType,
    ThemaRueckgabe: 'themaRueckgabe' as ActionDtoType,
    ThemaAntragRueckzug: 'themaAntragRueckzug' as ActionDtoType
};


import type { PageInfoDto } from '@/types/contracts/generated/models/pageInfoDto';
import type { InfoDto } from '@/types/contracts/generated/models/infoDto';
import type { PersonDto } from '@/types/contracts/generated/models/personDto';
import type { InfoType } from '@/types/contracts/generated/models/infoType';
import type { InfoListDto } from '@/types/contracts/generated/models/infoListDto';

import { defineStore } from 'pinia';
import { useCampusInfoApi } from '@/composables/http/use-campus-info-api';

const {
  fetchSentInfos,
  markRead,
  markReadAll,
  toggleFav,
  getBuddies,
  addBuddy,
  removeBuddy,
  fetchInfos,
  fetchFavInfos,
  getReplyInfo
} = useCampusInfoApi();

let markingRead = false;

/**
 * Declare States
 */
interface InfoStoreStates {
  infoList: InfoListDto | null;
  buddyList: PersonDto[];
  sentInfosPage: PageInfoDto | null;
  favInfoPage: PageInfoDto | null;
  directMessageRecipient: PersonDto | null;
  forwardOrReplyInfo: {
    type: string;
    info: InfoDto;
    recipients: PersonDto[];
    from: PersonDto;
  } | null;
  infoFilter: string;
}

/**
 * Export Store
 */
export const useInfoStore = defineStore('info', {
  state: (): InfoStoreStates => {
    return {
      infoList: null,
      buddyList: [],
      sentInfosPage: null,
      favInfoPage: null,
      directMessageRecipient: null,
      forwardOrReplyInfo: null,
      infoFilter: '',
    };
  },
  getters: {
    getUnread: (state) => () => {
      if (!state.infoList?.map) {
        return 0;
      }
      let totalUnread = 0;
      Object.keys(state.infoList.map).forEach((type) => {
        if (state.infoList?.map && state.infoList.map[type]) {
          totalUnread += state.infoList.map[type].unread ?? 0;
        }
      });
      return totalUnread;
    },

    getType: (state) => (type: string) => {
      if (!state.infoList?.map) {
        return {} as PageInfoDto;
      } else {
        if (state.infoList.map[type]) {
          return state.infoList.map[type];
        } else if (state.infoList.map[type.toUpperCase()]) {
          return state.infoList.map[type.toUpperCase()];
        } else {
          return {} as PageInfoDto;
        }
      }
    }
  },
  actions: {
    async fetchInfoLists() {
      try {
        const result = await fetchInfos();
        this.infoList = result;
      } catch (err) {
        console.error(err);
      }
    },

    async fetchSentInfoPage() {
      try {
        const result = await fetchSentInfos();
        this.sentInfosPage = result;
      } catch (err) {
        console.error(err);
      }
    },

    async fetchFavInfos() {
      try {
        const result = await fetchFavInfos();
        this.favInfoPage = result;
      } catch (err) {
        console.error(err);
      }
    },

    async markRead(message: InfoDto) {
      if (!(message.type && message.id)) {
        return;
      }

      if (!markingRead) {
        markingRead = true;
        try {
          await markRead(message);
          message.read = new Date().toISOString();
          if (this.infoList && this.infoList.map && this.infoList.map[message.type]) {
            this.infoList.map[message.type].unread = Math.max((this.infoList.map[message.type].unread || 0) - 1, 0);
          }
        } catch (err) {
          console.error(err);
        } finally {
          markingRead = false;
        }
      }
    },

    async markAllRead(type: InfoType) {
      try {
        await markReadAll({ type: type });
        if (this.infoList?.map && this.infoList.map[type] && this.infoList.map[type].data) {
          // @ts-ignore
          this.infoList.map[type].data.forEach((entry) => (entry.read = new Date().toLocaleString()));
          this.infoList.map[type].unread = 0;
        }
      } catch (err) {
        console.error(err);
      }
    },

    async toggleFave(message: InfoDto, type: string) {
      try {
        await toggleFav(message);
        message.fav = !message.fav;
        if (type === 'fav' && message.type) {
          const originalMsg = this.getType(message.type).data?.find((entry) => entry.id === message.id);
          if (originalMsg) {
            originalMsg.fav = false;
          }
        }
        await this.fetchFavInfos();
      } catch (err) {
        console.error(err);
      }
    },

    async fetchBuddies() {
      try {
        const result = await getBuddies();
        this.buddyList = result;
      } catch (err) {
        console.error(err);
      }
    },

    async addBuddy(id: number): Promise<void> {
      try {
        const result = await addBuddy(id);
        await this.fetchBuddies();
      } catch (err) {
        console.error(err);
      }
    },

    async removeBuddy(id: number): Promise<void> {
      try {
        const result = await removeBuddy(id);
        await this.fetchBuddies();
      } catch (err) {
        console.error(err);
      }
    },

    async forwardOrReplyMessage(actionType: string, info: InfoDto) {
      switch (actionType) {
        case 'forward': {
          const resp = await getReplyInfo(info.id ?? -1);
          this.forwardOrReplyInfo = {
            type: actionType,
            info: info,
            recipients: [],
            from: resp.fromperson ?? {}
          };
          break;
        }
        case 'reply': {
          const resp = await getReplyInfo(info.id ?? -1);
          this.forwardOrReplyInfo = {
            type: actionType,
            info: info,
            recipients: resp.fromperson ? [resp.fromperson] : [],
            from: resp.fromperson ?? {}
          };
          break;
        }
        case 'replyAll': {
          const resp = await getReplyInfo(info.id ?? -1);
          const recipients = resp.receiver?.map((entry) => entry.person ?? {}) ?? [];
          recipients.push(resp.fromperson ?? {});
          this.forwardOrReplyInfo = {
            type: actionType,
            info: info,
            recipients: recipients,
            from: resp.fromperson ?? {}
          };
          break;
        }
      }
      return;
    }
  }
});

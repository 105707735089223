<template>
  <div>
    <Dialog v-model:visible="visible" modal :closable="false" :draggable="false" :pt="{
      root: {
        class: 'max-w-[90vw] lg:!max-w-4xl lg:min-w-[896px]'
      },
      content: {
        class: '!p-0'
      }
    }">
      <template #header>
        <div v-if="showSubmitMessage">
          <p class="mb-0 text-lg font-semibold">{{ $t("EducationStep.dialog.automatic_crediting.headline") }}</p>
        </div>
        <div v-else class="flex flex-col">
          <p class="text-lg font-bold lg:text-xl text-zinc-600">{{ $t('statistical_data_stepper.intro.headline', {
            firstName:
              compactProfile.vorname, lastName: compactProfile.nachname
          }) }}</p>
          <p class="mb-2 text-sm lg:text-base">{{ $t('statistical_data_stepper.intro.subline') }}</p>
          <p class="mb-0 text-sm lg:text-base">{{ $t('statistical_data_stepper.intro.note') }}</p>
        </div>
      </template>
      <div v-if="showSubmitMessage" class="p-8">
        <p>{{ $t("EducationStep.dialog.automatic_crediting.text") }}</p>
        <p class="font-semibold">{{ $t("EducationStep.dialog.individual_crediting.headline") }}</p>
        <p v-html="$t('EducationStep.dialog.individual_crediting.text')"></p>
        <div class="flex justify-end">
          <PrimaryButton :label="$t('EducationStep.dialog.submit')" @click="handleFinish" />
        </div>
      </div>
      <Stepper v-if="!showSubmitMessage && questionnaireDropDownData" @step-change="scrollToTop" linear :pt="{
        root: {
          class: 'h-full overflow-y-auto relative'
        },
        nav: {
          class: 'm-0 p-4 lg:px-8 lg:py-6 fixed w-full bg-white z-10',
        },
        panelContainer: {
          class: 'text-zinc-800 p-0 pt-24'
        },
      }">
        <StepperPanel :header="$t('statistical_data_stepper.educational_background')" :pt="ptStepper">
          <template #content="{ nextCallback }">
            <StatisticalDataStep1 :questionnaire-drop-down-data="questionnaireDropDownData" :education="education"
              @formComplete="(data: any) => handleEducationStep(data, nextCallback)" />
          </template>
        </StepperPanel>
        <StepperPanel v-if="showPreviousEducationsStep" :header="$t('statistical_data_stepper.student_days')"
          :pt="ptStepper">
          <template #content="{ prevCallback, nextCallback }">
            <StatisticalDataStep2 :questionnaire-drop-down-data="questionnaireDropDownData"
              :previousEducations="previousEducations" :prev-callback="() => handleGoBackToEducationStep(prevCallback)"
              @formComplete="(data: any) => handlePreviousEducationsStep(data, nextCallback)" />
          </template>
        </StepperPanel>
        <StepperPanel :header="$t('statistical_data_stepper.summary')" :pt="ptStepper">
          <template #content="{ prevCallback }">
            <StatisticalDataStepSummary :questionnaire-drop-down-data="questionnaireDropDownData" :education="education"
              :prev-callback="prevCallback" @submit="handleFormComplete()" />
          </template>
        </StepperPanel>
      </Stepper>
    </Dialog>
  </div>
</template>

<script lang="ts" setup>
import type { QuestionnaireDropDownData } from '@/types/contracts/generated/models/questionnaireDropDownData';
import type { Education, PreviousEducation } from './partials/StatisticalDataTypes';

import { ref } from 'vue';
import { useCampusHstatgApi } from '@/composables/http/use-campus-hstatg-api';
import Stepper from 'primevue/stepper';
import StepperPanel from 'primevue/stepperpanel';
import StatisticalDataStep1 from './partials/StatisticalDataStep1.vue';
import StatisticalDataStep2 from './partials/StatisticalDataStep2.vue';
import StatisticalDataStepSummary from './partials/StatisticalDataStepSummary.vue';
import Dialog from 'primevue/dialog';
import PrimaryButton from '@/components/buttons/PrimaryButton.vue';
import { usePersonStore } from '@/stores/person';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
import { useSitemapStore } from '@/stores/sitemap';

const { getDropdownData, saveStatisticData } = useCampusHstatgApi();
const { showStatisticalData } = storeToRefs(useSitemapStore());
const questionnaireDropDownData = ref<QuestionnaireDropDownData>();
const { compactProfile } = storeToRefs(usePersonStore());
const visible = ref(true);
const showSubmitMessage = ref();

const scrollToTop = () => document.querySelector('.p-stepper')!.scrollTop = 0;

type ExtendedPreviousEducation = PreviousEducation & { index: number };

const education = ref<Education>({
  citizenShipOne: undefined,
  citizenShipTwo: "-",
  countryOfBirth: undefined,
  placeOfBirth: undefined,
  currentJob: undefined,
  educationStatusInfo: undefined,
  vocationalSchoolParticipation: undefined,
  vocationalSchoolParticipationId: undefined,
  furtherTraining: undefined,
  collegeUniversityExperience: undefined,
  hzbTraining: undefined,
  hzbTrainingFinished: undefined,
  hzbYear: undefined,
  hzbTypeId: undefined,
  hzbCountryId: undefined,
  hzbCityId: undefined,
  previousEducations: undefined
})

const previousEducations = ref<ExtendedPreviousEducation[]>([
  {
    completed: undefined,
    studyPlace: undefined,
    universityTypeId: undefined,
    countryId: undefined,
    cityId: undefined,
    universityId: undefined,
    fieldOfStudyId: undefined,
    studyModelId: undefined,
    fromSemesterId: undefined,
    toSemesterId: undefined,
    ects: undefined,
    numberOfLeaveSemesters: 0,
    gradeId: undefined,
    academicDegreeId: undefined,
    monthOfGraduationExamination: undefined,
    yearOfGraduationExamination: undefined,
    reasonForExmatriculationId: undefined
  }
])

const showPreviousEducationsStep = ref(education.value.collegeUniversityExperience);

const fetchData = async () => {
  questionnaireDropDownData.value = await getDropdownData();
}
fetchData();

const handleEducationStep = (data: Education, nextCallback: Function) => {
  showPreviousEducationsStep.value = data.collegeUniversityExperience;
  education.value = data;
  nextCallback();
}

const handleGoBackToEducationStep = (prevCallback: Function) => {
  education.value.previousEducations = undefined;
  prevCallback();
}

const handlePreviousEducationsStep = (data: PreviousEducation[], nextCallback: Function) => {
  education.value.previousEducations = data;
  nextCallback();
}

const handleFormComplete = () => {
  const data = education.value as any;
  if (data.previousEducations) data.previousEducations.forEach((previousEducation: PreviousEducation) => {
    if (previousEducation.gradeId) {
      (previousEducation.gradeId as unknown as string) = previousEducation.gradeId.toString();
    }
  });
  saveStatisticData(data);
  showSubmitMessage.value = true;
}

const handleFinish = () => {
  showStatisticalData.value = false;
}

const ptStepper = {
  root: {
    class: 'h-full'
  },
  header: {
    class: `
    [&>.p-stepper-action>.p-stepper-number]:h-8
    [&>.p-stepper-action>.p-stepper-number]:w-8
    [&>.p-stepper-action>.p-stepper-number]:text-lg
    [&>.p-stepper-action>.p-stepper-number]:font-semibold
    [&>.p-stepper-action>.p-stepper-number]:text-zinc-500
    [&>.p-stepper-action>.p-stepper-number]:border-2
    [&>.p-stepper-action>.p-stepper-number]:border-solid
    [&>.p-stepper-action>.p-stepper-number]:border-zinc-300
    [&>.p-stepper-action>.p-stepper-number]:bg-transparent`
  },
  content: {
    class: 'h-full'
  }
}
</script>

<style scoped>
.p-stepper {
  flex-basis: 50rem;
}

:deep(.p-stepper-header) {
  .p-stepper-action {
    @apply flex-col;

    .p-stepper-title {
      @apply mt-2 ml-0 text-zinc-700 text-base font-semibold;
    }
  }

  .p-stepper-separator {
    @apply bg-zinc-300 mb-8;
  }
}

:deep(.p-stepper-header.p-highlight) {
  .p-stepper-action {
    .p-stepper-number {
      @apply bg-primary-500 !text-white border-primary-500;
    }

    .p-stepper-title {
      @apply !text-primary-500;
    }
  }
}

:deep(.nf-radio-button-group) {
  @apply [&>div]:gap-8;
}

:deep(.nf-radio-button-group-radio-container) {
  @apply w-fit justify-start items-start first:whitespace-nowrap gap-2 text-zinc-800;
}
</style>

<template>
  <NfInputFloatLabel :input-name="inputName" :label="label">
    <Dropdown
      :id="inputName"
      v-model="value"
      :class="{ 'p-invalid': hasError }"
      class="w-full"
      :option-label="optionLabel"
      :option-value="optionValue"
      :options="options"
      @change="(event: DropdownChangeEvent) => emits('change', event)"
      :pt="pt"
      :placeholder="placeholder"
      :editable="props.editable == true"
      :filter="filter"
      :disabled="props.disabled"
    >
      <template v-if="$slots.value" #value="{ value, placeholder }">
        <slot name="value" :value="value" :placeholder="placeholder"></slot>
      </template>
      <template v-if="$slots.header" #header="{ value, options }">
        <slot name="header" :value="value" :options="options"></slot>
      </template>
      <template v-if="$slots.footer" #footer="{ value, options }">
        <slot name="footer" :value="value" :options="options"></slot>
      </template>
      <template v-if="$slots.option" #option="{ option, index }">
        <slot name="option" :index="index" :option="option"></slot>
      </template>
      <template v-if="$slots.optionGroup" #optiongroup="{ option, index }">
        <slot name="optiongroup" :index="index" :option="option"></slot>
      </template>
      <template v-if="$slots.emptyfilter" #emptyfilter>
        <!-- @vue-ignore -->
        <slot name="emptyfilter"></slot>
      </template>
      <template v-if="$slots.empty" #empty>
        <!-- @vue-ignore -->
        <slot name="empty"></slot>
      </template>
      <template v-if="$slots.filtericon" #filtericon>
        <!-- @vue-ignore -->
        <slot name="filtericon"></slot>
      </template>
      <template v-if="$slots.loadingicon" #loadingicon="scope">
        <slot name="loadingicon" :class="scope.class"></slot>
      </template>
      <template v-if="$slots.dropdownicon" #dropdownicon="scope">
        <slot name="dropdownicon" :class="scope.class"></slot>
      </template>
      <template v-if="$slots.clearicon" #clearicon="scope">
        <slot name="clearicon" class="clearicon" :onClick="scope.onClick" :clearCallback="scope.onClick"></slot>
      </template>
      <template v-if="$slots.loader" #loader="{ options }">
        <slot name="loader" :options="options"></slot>
      </template>
      <template v-if="$slots.content" #content="{ items, styleClass, contentRef, getItemOptions }">
        <slot
          name="content"
          :items="items"
          :styleClass="styleClass"
          :contentRef="contentRef"
          :getItemOptions="getItemOptions"
        ></slot>
      </template>
    </Dropdown>
  </NfInputFloatLabel>
</template>

<script lang="ts">
import type { DropdownSlots, DropdownChangeEvent, DropdownPassThroughOptions } from 'primevue/dropdown';

export interface NfDropdownProps {
  inputName: string;
  modelValue?: number;
  label?: string;
  dropdown?: boolean;
  options: any[];
  optionLabel?: string;
  optionValue?: string;
  pt?: DropdownPassThroughOptions;
  standalone?: boolean;
  placeholder?: string;
  editable?: boolean;
  filter?: boolean;
  disabled?: boolean;
}

export interface NfDropdownEmits {
  (e: 'update:modelValue', value: string): void;
  (e: 'change', value: DropdownChangeEvent): void;
}

// Default Export, used for IDE auto-importing only
export default {
  name: 'NfDropdown'
}
</script>

<script lang="ts" setup>
import Dropdown from 'primevue/dropdown';
import NfInputFloatLabel from '@/components/form/NfInputFloatLabel.vue';
import { useFormField } from '@/composables/form/use-form-field';

// @todo Deprecated / Legacy Code
// Define Component
const props = defineProps<NfDropdownProps>();
const emits = defineEmits<NfDropdownEmits>();
const slots = defineSlots<DropdownSlots>();

// Form
const { value, hasError } = useFormField(props, emits, !props.standalone);
</script>

<style scoped></style>

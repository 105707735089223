import type { MenuItem } from 'primevue/menuitem';
import type { RouteRecordName, RouteRecordRaw, RouteLocationNormalized } from 'vue-router';
import type { Theme } from '@/types/contracts/generated/models/theme';
import type { ExtendedMenuNodeDto } from '@/models/ExtendedMenuNodeDto';

import { defineStore } from 'pinia';
import { useCampusUserApi } from '@/composables/http/use-campus-user-api';
import RouteTable from '@/constants/route-table';
import RouteStatics from '@/constants/route-statics';

function generateMobileMenu(nodes: ExtendedMenuNodeDto[]): MenuItem[] {
  const menu: MenuItem[] = [];
  nodes.forEach((entry) => {
    menu.push({
      key: `${entry.path?.join('_')}${entry.path && entry.path.length > 0 ? '_' : ''}${entry.nodeid}`,
      label: entry.i18n,
      route: entry.children && entry.children.length > 0 ? undefined : `/node/${entry.includeid ?? entry.nodeid}`,
      items: entry.children && entry.children.length > 0 ? generateMobileMenu(entry.children) : undefined
    });
  });
  return menu;
}

function generateSubMenu(node: ExtendedMenuNodeDto): ExtendedMenuNodeDto[] {
  const tmp: ExtendedMenuNodeDto[] = [];
  node.children?.forEach((entry) => {
    const cp = JSON.parse(JSON.stringify(entry));
    cp.children = null;
    tmp.push(cp);
  });
  return tmp;
}

function findNodeById(nodes: ExtendedMenuNodeDto[], nodeId: number): ExtendedMenuNodeDto | null {
  let result: ExtendedMenuNodeDto | null = null;
  for (const entry of nodes) {
    if (entry.nodeid === nodeId) {
      result = entry;
      break;
    } else if (entry.children && entry.children.length > 0) {
      result = findNodeById(entry.children, nodeId);
      if (result !== null) {
        break;
      }
    }
  }
  return result;
}

/**
 * Declare States
 */
interface SitemapStoreStates {
  theme: Theme | null;
  showStatisticalData: boolean;
  sitemap: ExtendedMenuNodeDto[];
  activeNode: ExtendedMenuNodeDto | null;
  mainMenu: ExtendedMenuNodeDto[];
  topMenu: ExtendedMenuNodeDto[] | null;
  subSideMenu: ExtendedMenuNodeDto[] | null;
  mobileMenu: MenuItem[];
  mobileProfileMenu: MenuItem[];
  expandedKeys: Record<string, boolean>;
  previous: RouteLocationNormalized | null;
  baseError: boolean,
}

/**
 * Export Store
 */
export const useSitemapStore = defineStore('sitemap', {
  state: (): SitemapStoreStates => ({
    theme: null,
    showStatisticalData: false,
    sitemap: [],
    activeNode: null,
    mainMenu: [],
    topMenu: null,
    subSideMenu: null,
    mobileMenu: [],
    mobileProfileMenu: [],
    expandedKeys: {},
    previous: null,
    baseError: false
  }),
  getters: {},
  actions: {
    /**
     * Fetch and Prepare Routes
     */
    async fetchRoutes() {
      const pages = import.meta.glob('./../pages/**/*.vue');
      const campusUserApi = useCampusUserApi();

      // Fetch API Data
      let result;
      try {
        result = await campusUserApi.fetchBase();
      } catch (e) {
        this.baseError = true;
      }
      if (typeof result?.menu == 'undefined' || typeof result?.theme == 'undefined') {
        throw new Error('The received server response is invalid.');
      }


      // Loop API Routes
      for (const [route, parent] of this.assign(result.menu as ExtendedMenuNodeDto[])) {
        let page = 'ContentPage';
        const type = route.moduletype || (route as any).component || 'Content';
        if (type !== 'Content') {
          page = RouteTable[type] || type || page;
        }
        const component: any = pages[`../pages/${page}.vue`];

        // Set Route
        const args: [RouteRecordName | RouteRecordRaw, RouteRecordRaw?] = [
          {
            name: (route.nodeid || route.name || '').toString(),
            path: (parent && typeof route.path == 'string') ? route.path : `/node/${route.nodeid}`,
            component,
            meta: {
              title: (parent && (route as any).i18nName) ? (route as any).i18nName : route.i18n,
              image: route.image || null,
              extended: route,
              view: page
            }
          }
        ];

        if (parent) {
          (args[0] as any).props = true;
          args.unshift((parent.nodeid || parent.name || '').toString());
        }

        // Assign Route
        this.$router.addRoute(...(args as [RouteRecordRaw]));
      }

      // Loop Enhanced Static Routes
      Object.entries(RouteStatics).forEach(([path, route]) => {
        const component: any = pages[`../pages/${route.component}.vue`];
        this.$router.addRoute(route.parent, {
          name: route.name || path,
          path,
          component
        });
      });

      // Assign States
      this.theme = result.theme;
      this.sitemap = result.menu as ExtendedMenuNodeDto[];
      this.showStatisticalData = result.openstatistik ?? false;
      this.register();
    },

    /**
     * Private Generator, used for the fetchRoutes initial loader method.
     * @param routes
     * @param parent
     */
    *assign(
      routes: ExtendedMenuNodeDto[],
      parent?: ExtendedMenuNodeDto
    ): Generator<[ExtendedMenuNodeDto, ExtendedMenuNodeDto | null]> {
      for (const route of routes) {
        yield [route, parent || null];

        // Child-Routes
        if (route.children && route.children.length >= 0) {
          for (const [a, b] of this.assign(route.children)) {
            yield [a, b];
          }
        }

        // Sub-Routes
        if (route.subroutes && route.subroutes.length > 0) {
          for (const [a, b] of this.assign(route.subroutes as ExtendedMenuNodeDto[], route)) {
            yield [a, b];
          }
        }
      }
    },

    /**
     * Private method used for menu registrations of the initial fetchRoutes loader method.
     */
    register() {
      this.mainMenu = [...this.sitemap].map((e) => Object.assign(JSON.parse(JSON.stringify(e)), { children: null }));
      this.mobileMenu = generateMobileMenu(this.sitemap.filter((entry) => !entry.profil));
      this.mobileProfileMenu = generateMobileMenu(this.sitemap.filter((entry) => entry.profil));

      // Set Home
      const firstNav = this.sitemap.find((entry) => entry.profil === false);
      if (firstNav) {
        this.$router.addRoute({
          path: '/',
          redirect: {
            path: `/node/${firstNav.includeid ?? firstNav.nodeid}`
          }
        });
      }

      // Redirect DeepLink
      const deepLink = window.sessionStorage.getItem('deepLink');
      if (deepLink != null && deepLink !== '' && deepLink !== '/') {
        this.$router.replace({ path: deepLink });
        sessionStorage.removeItem('deepLink');
      } else if (firstNav) {
        this.$router.replace(`/node/${firstNav.includeid ?? firstNav.nodeid}`);
      }

      // Router Hook
      this.$router.beforeResolve((to) => {
        const path = to.fullPath.split('/');
        const idx = path.findIndex((entry) => entry === 'node');

        // Set Node & Title
        if (idx > 0 && path.length >= idx + 1) {
          this.setActiveNode(+path[idx + 1]);
        } else {
          this.activeNode = null;
          this.topMenu = null;
          this.subSideMenu = null;
          this.expandedKeys = {};
        }

        let translated = to.meta.title?.includes('meta.title') ? this.$i18n.t(to.meta.title) : to.meta.title;
        if (translated?.startsWith('OC |')) {
          translated = translated.slice(5);
        }
        document.title = (translated && translated.length > 0) ? `OC | ${translated}` : 'Online-Campus';
        document.documentElement.dataset.page = to.meta.view || to.meta.extended?.moduletype || 'unknown';
      });

      // Register Previous Route Listener
      this.$router.beforeResolve((to, from) => {
        this.previous = from;
      })
    },

    /**
     *
     * @todo Cleanup
     * @param nodeId
     */
    setActiveNode(nodeId: number) {
      const node = findNodeById(this.sitemap, nodeId);
      this.topMenu = null;
      this.subSideMenu = null;
      if (node) {
        node.path = node.path?.filter((nodeid: number) => nodeid !== 6099);
        this.activeNode = node;
        if (this.activeNode.path?.length === 1) {
          const parent = findNodeById(this.sitemap, this.activeNode.path[0]);
          if (parent) {
            this.topMenu = generateSubMenu(parent);
          }
        } else if (this.activeNode.path?.length && this.activeNode.path?.length >= 2) {
          let parent = findNodeById(this.sitemap, this.activeNode.path[1]);
          if (parent) {
            this.subSideMenu = generateSubMenu(parent);
          }
          parent = findNodeById(this.sitemap, this.activeNode.path[0]);
          if (parent) {
            this.topMenu = generateSubMenu(parent);
          }
        }
        this.expandedKeys = {};
        let key = '';
        if (this.activeNode.path) {
          for (const entry of this.activeNode.path) {
            if (key === '') {
              key = entry.toString();
            } else {
              key += '_' + entry;
            }
            this.expandedKeys[key] = true;
          }
        }
      }
    }
  }
});

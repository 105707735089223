<template>
  <NfInputFloatLabel :inputName="inputName" :label="label">
    <InputNumber
      :id="inputName"
      :useGrouping="false"
      :show-buttons="showButtons"
      :step="step"
      v-model="value"
      v-bind="$props" 
      :min="min"
      :max="max"
      :mode="mode"
      :min-fraction-digits="minFractionDigits"
      :max-fraction-digits="maxFractionDigits"
      :class="{ 'p-invalid': hasError }"
      :allow-empty="allowEmpty"
    />
  </NfInputFloatLabel>
</template>

<script lang="ts">
export interface NfInputNumberProps {
  inputName: string;
  modelValue?: number;
  label?: string;
  standalone?: boolean;
  showButtons?: boolean;
  step?: number;
  min?: number;
  max?: number;
  allowEmpty?: boolean;
  mode?: "decimal" | "currency" | undefined;
  minFractionDigits?: number;
  maxFractionDigits?: number;
  class?: string;
}

export interface NfInputNumberEmits {
  (e: 'update:modelValue', value: string): void;
}

// Default Export, used for IDE auto-importing only
export default {
  name: 'NfInputNumber'
}
</script>

<script lang="ts" setup>
import InputNumber from 'primevue/inputnumber';
import NfInputFloatLabel from '@/components/form/NfInputFloatLabel.vue';
import { useFormField } from '@/composables/form/use-form-field';

// @todo Deprecated / Legacy Code
// Define Component
const props = defineProps<NfInputNumberProps>();
const emits = defineEmits<NfInputNumberEmits>();

const { value, hasError } = useFormField<number>(props, emits, !props.standalone);
</script>

<style scoped>
:deep(.p-button) {
  @apply p-0 rounded-none;
}

:deep(.p-inputnumber-button-up) {
  @apply rounded-tr-md;
}
:deep(.p-inputnumber-button-down) {
  @apply rounded-br-md;
}

:deep(.p-inputnumber-input:has(+.p-inputnumber-button-group)) {
  @apply rounded-tr-none rounded-br-none;
}
</style>

import { useI18n } from 'vue-i18n';

export const getStatisticalDataEducationLabels = (questionnaireDropDownData: any, education: any) => {
  const { t } = useI18n();
  return new Map([
    [
      'citizenShipOne',
      education.citizenShipOne
        ? {
            label: t('EducationStep.PersonalInformation.CitizenshipHeader'),
            value:
              questionnaireDropDownData.staatsangehoerigkeitViewModels.find(
                (obj: any) => obj.id === education.citizenShipOne
              ).value || 'N/A'
          }
        : null
    ],
    [
      'citizenShipTwo',
      education.citizenShipTwo
        ? {
            label: t('EducationStep.PersonalInformation.CitizenShipTwo'),
            value:
              questionnaireDropDownData.staatsangehoerigkeitZweiViewModels.find(
                (obj: any) => obj.id === education.citizenShipTwo
              ).value || 'N/A'
          }
        : null
    ],
    [
      'countryOfBirth',
      education.countryOfBirth
        ? {
            label: t('EducationStep.PersonalInformation.CountryOfBirth'),
            value:
              questionnaireDropDownData.geburtslandViewModels.find((obj: any) => obj.id === education.countryOfBirth)
                .value || 'N/A'
          }
        : null
    ],
    [
      'placeOfBirth',
      {
        label: t('EducationStep.PersonalInformation.PlaceOfBirth'),
        value: education.placeOfBirth || 'N/A'
      }
    ],
    [
      'currentJob',
      {
        label: t('EducationStep.PersonalInformation.CurrentJob.Label'),
        value: education.currentJob ? t('EducationStep.yes') : t('EducationStep.no')
      }
    ],
    [
      'educationStatusInfo',
      {
        label: t('EducationStep.EducationStatusInfo'),
        value: education.educationStatusInfo ? t('EducationStep.yes') : t('EducationStep.no')
      }
    ],
    [
      'vocationalSchoolParticipation',
      {
        label: t('EducationStep.VocationalSchoolParticipation'),
        value: education.vocationalSchoolParticipation ? t('EducationStep.yes') : t('EducationStep.no') // check needed
      }
    ],
    [
      'vocationalSchoolParticipationId',
      education.vocationalSchoolParticipationId
        ? {
            label: t('EducationStep.VocationalSchoolParticipationId.label'),
            value:
              questionnaireDropDownData.berufskollegViewModels.find(
                (obj: any) => obj.id === education.vocationalSchoolParticipationId
              )?.value || 'N/A'
          }
        : null
    ],
    [
      'furtherTraining',
      education.furtherTraining
        ? {
            label: t('EducationStep.FurtherTraining.label'),
            value:
              questionnaireDropDownData.weiterbildungViewModels.find((obj: any) => obj.id === education.furtherTraining)
                .value || 'N/A'
          }
        : null
    ],
    [
      'collegeUniversityExperience',
      {
        label: t('EducationStep.CollegeUniversityExperience'),
        value: education.collegeUniversityExperience ? t('EducationStep.yes') : t('EducationStep.no')
      }
    ],
    [
      'hzbTraining',
      education.hzbTraining
        ? {
            label: t('EducationStep.HzbTraining.label'),
            value:
              questionnaireDropDownData.ausbildungsberufViewModels.find((obj: any) => obj.id === education.hzbTraining)
                .value || 'N/A'
          }
        : null
    ],
    [
      'hzbTrainingFinished',
      {
        label: t('EducationStep.HzbTrainingFinished'),
        value: education.hzbTrainingFinished ? t('EducationStep.yes') : t('EducationStep.no')
      }
    ],
    [
      'hzbYear',
      education.hzbYear
        ? {
            label: t('EducationStep.HzbYear.label'),
            value: education.hzbYear
          }
        : null
    ],
    [
      'hzbTypeId',
      education.hzbTypeId
        ? {
            label: t('EducationStep.HzbType.label'),
            value:
              questionnaireDropDownData.hochschulTypViewModels.find((type: any) => type.id === education.hzbTypeId)
                .value || 'N/A'
          }
        : null
    ],
    [
      'hzbCountryId',
      education.hzbCountryId
        ? {
            label: t('EducationStep.HzbCountry.label'),
            value:
              questionnaireDropDownData.geburtslandViewModels.find((obj: any) => obj.id === education.hzbCountryId)
                .value || 'N/A'
          }
        : null
    ],
    [
      'hzbCityId',
      education.hzbCityId
        ? {
            label: t('EducationStep.HzbCity.label'),
            value:
              questionnaireDropDownData.cityViewModels.find((obj: any) => obj.id === education.hzbCityId)?.value ||
              'N/A'
          }
        : null
    ]
  ]);
};

export const getStatisticalDataPreviousEducationLabels = (
  questionnaireDropDownData: any,
  education: any,
  index: number
) => {
  const { t } = useI18n();
  return new Map<any, any>([
    [
      'completed',
      {
        label: t('PreviousEducationStep.HaveYouSuccessfullyCompletedYourStudies.title'),
        value: education.previousEducations[index].completed
          ? t('PreviousEducationStep.HaveYouSuccessfullyCompletedYourStudies.Yes')
          : t('PreviousEducationStep.HaveYouSuccessfullyCompletedYourStudies.No')
      }
    ],
    [
      'studyPlace',
      {
        label: t('PreviousEducationStep.StudyPlace'),
        value:
          education.previousEducations[index].studyPlace === 'germany'
            ? t('PreviousEducationStep.InGermany')
            : t('PreviousEducationStep.Abroad')
      }
    ],
    [
      'universityTypeId',
      {
        label: t('PreviousEducationStep.UniversityType'),
        value: questionnaireDropDownData.ausbildungsTypViewModels.find(
          (obj: any) => obj.id === education.previousEducations[index].universityTypeId
        )?.value
      }
    ],
    [
      'countryId',
      education.previousEducations[index].countryId
        ? {
            label: t('PreviousEducationStep.Country'),
            value: questionnaireDropDownData.staatsangehoerigkeitZweiViewModels.find(
              (obj: any) => obj.id === education.previousEducations[index].countryId
            )?.value
          }
        : null
    ],
    [
      'cityId',
      education.previousEducations[index].cityId
        ? {
            label: t('PreviousEducationStep.City'),
            value:
              questionnaireDropDownData.cityViewModels.find(
                (obj: any) => obj.id === education.previousEducations[index].cityId
              )?.value || education.previousEducations[index].cityId
          }
        : null
    ],
    [
      'universityId',
      education.previousEducations[index].universityId
        ? {
            label: t('PreviousEducationStep.University'),
            value:
              questionnaireDropDownData.hochschuleViewModels.find(
                (obj: any) => obj.id === education.previousEducations[index].universityId
              )?.value || education.previousEducations[index].universityId
          }
        : null
    ],
    [
      'fieldOfStudyId',
      education.previousEducations[index].fieldOfStudyOther
        ? null
        : {
            label: t('PreviousEducationStep.FieldOfStudy'),
            value: questionnaireDropDownData.bundStudienfachViewModels.find(
              (obj: any) => obj.id === education.previousEducations[index].fieldOfStudyId
            )?.value
          }
    ],
    [
      'fieldOfStudyOther',
      education.previousEducations[index].fieldOfStudyOther
        ? null
        : {
            label: t('PreviousEducationStep.FieldOfStudyOther'),
            value: education.previousEducations[index].fieldOfStudyId
          }
    ],
    [
      'studyModelId',
      {
        label: t('PreviousEducationStep.StudyModel'),
        value: questionnaireDropDownData.zeitmodellViewModels.find(
          (obj: any) => obj.id === education.previousEducations[index].studyModelId
        )?.value
      }
    ],
    [
      'fromSemesterId',
      {
        label: t('PreviousEducationStep.From'),
        value: questionnaireDropDownData.semesterKuerzelViewModels.find(
          (obj: any) => obj.id === education.previousEducations[index].fromSemesterId
        )?.value
      }
    ],
    [
      'toSemesterId',
      {
        label: t('PreviousEducationStep.To'),
        value: questionnaireDropDownData.semesterKuerzelViewModels.find(
          (obj: any) => obj.id === education.previousEducations[index].toSemesterId
        )?.value
      }
    ],
    [
      'ects',
      education.previousEducations[index].ects
        ? {
            label: t('PreviousEducationStep.Ects'),
            value:
              questionnaireDropDownData.ectsViewModels.find(
                (obj: any) => obj.id === education.previousEducations[index].ects
              )?.value ||
              education.previousEducations[index].ects ||
              education.previousEducations[index].ects
          }
        : null
    ],
    [
      'numberOfLeaveSemesters',
      {
        label: t('PreviousEducationStep.NumberOfLeaveSemesters'),
        value: education.previousEducations[index].numberOfLeaveSemesters
      }
    ],
    [
      'gradeId',
      education.previousEducations[index].gradeId
        ? {
            label: t('PreviousEducationStep.Grade'),
            value: questionnaireDropDownData.noteViewModels?.find(
              (grade: any) => grade.id === education.previousEducations[index].gradeId
            ).value
          }
        : null
    ],
    [
      'academicDegreeId',
      education.previousEducations[index].academicDegreeId
        ? {
            label: t('PreviousEducationStep.AcademicDegree'),
            value: questionnaireDropDownData.titelViewModels.find(
              (title: any) => title.id == education.previousEducations[index].academicDegreeId
            )?.value
          }
        : null
    ],
    [
      'yearOfGraduationExamination',
      education.previousEducations[index].monthOfGraduationExamination &&
      education.previousEducations[index].yearOfGraduationExamination
        ? {
            label: t('PreviousEducationStep.DateOfGraduationExamination'),
            value: `${t(`PreviousEducationStep.months[${parseInt(education.previousEducations[index].monthOfGraduationExamination) - 1}]`)}, ${education.previousEducations[index].yearOfGraduationExamination}`
          }
        : null
    ],
    [
      'reasonForExmatriculationId',
      {
        label: t('PreviousEducationStep.ReasonForExmatriculation'),
        value: questionnaireDropDownData.exmatrikulationViewModels.find(
          (obj: any) => obj.id === education.previousEducations[index].reasonForExmatriculationId
        )?.value
      }
    ],
    [
      'reasonForTermination',
      education.previousEducations[index].reasonForTermination
        ? {
            label: t('PreviousEducationStep.ReasonForTermination'),
            value: questionnaireDropDownData.abbruchViewModels.find(
              (obj: any) => obj.id === education.previousEducations[index].reasonForTermination
            )?.value
          }
        : null
    ]
    // ["exmatriculationDate", {
    //   label: t("PreviousEducationStep.ExmatriculationDate"),
    //   value: d(new Date(education.previousEducations![index].exmatriculationDate!), 'dd-mm-yyyy')
    // }],
  ]);
};
